<template>
  <div v-if="!file" class="upload-file" :style="style">
    <input
      ref="inputFile"
      accept="application/pdf,image/png,image/jpeg,image/jpg"
      type="file"
      @change="onFileChange($event)"
    />
    <button
      class="btn btn-outline-primary"
      :style="{
        backgroundColor: buttonBackgroundColor,
        color: buttonTextColor,
        borderColor: buttonTextColor,
      }"
      @click="uploadFile()"
    >
      <i class="bi bi-cloud-arrow-up" />
      {{ $t("press-drag-to-upload-documents") }}
    </button>
  </div>
  <div v-else class="uploaded-file" :style="style">
    <div class="file-container d-flex justify-content-between">
      <div class="file-content d-flex">
        <i class="bi bi-file-earmark" />
        <div class="information">
          <span class="title">{{ file.name }} </span>
          <br />
          {{ humanFileSize(file.size) }}
        </div>
      </div>
      <div class="file-close">
        <button class="btn btn-light" @click="onFileChange(null)">
          <i class="bi bi-x-circle" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import { humanFileSize } from "@/core/helpers/file";

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    buttonBackgroundColor: {
      type: String,
      default: null,
    },
    buttonTextColor: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputFile = ref();
    const file = ref(props.modelValue || null);

    const uploadFile = () => {
      inputFile.value.click();
    };
    const onFileChange = (event) => {
      const files = event?.target?.files;
      let selectedFile = files && files[0] ? files[0] : null;
      const allowedExtensions = /(\.(pdf|jpeg|jpg|png))$/i;
      if (selectedFile) {
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

        if (!allowedExtensions.exec(selectedFile.name)) {
          selectedFile = null;
          inputFile.value.value = null;
          selectedFile = null;
        }

        if (selectedFile.size > maxSize) {
          inputFile.value.value = null;
          selectedFile = null;
        }
      }
      file.value = selectedFile;
      emit("update:model-value", file.value);
    };

    const style = computed(() => {
      return {
        "--button-text-color": props.buttonTextColor || "#f4a63d",
        "--button-background-color": props?.buttonBackgroundColor || "#fdfaf6",
      };
    });
    return {
      inputFile,
      uploadFile,
      onFileChange,
      file,
      humanFileSize,
      style,
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-file {
  width: 100%;
  background-color: #f5f5f5;
  padding: 0 10px;
  height: 80px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--button-text-color);
  }
}
input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
button {
  z-index: 1;
}

.uploaded-file {
  border: 1px solid var(--button-text-color);
  width: 100%;
  background-color: var(--button-background-color);
  min-height: 80px;
  border-radius: 4px;
  padding: 0 15px;

  .title {
    color: var(--button-text-color);
    font-size: 16px;
  }

  .file-content {
    i {
      font-size: 50px;
      color: var(--button-text-color);
      margin-right: 15px;
    }
    .information {
      margin-top: 12px;
    }
  }
  .file-close {
    button {
      background-color: white;
      margin-top: 12px;
      border-radius: 100%;
      i {
        color: var(--button-text-color);
        font-size: 25px;
      }
    }
  }
}
</style>
