import { NODE_ENV } from "@/config";
import router from "../../../router";
import { USER_SESSION } from "@/constants/SessionStorage";
export const requestErrorHandler = () => {
  // toast
};

export const responseErrorHandler = (error) => {
  if (error.isAxiosError) {
    if (!error.response) {
      // toast
    }

    const { data, status } = error.response;
    const { errors, title } = data;

    if (status === 400) {
      // toast

      if (NODE_ENV !== "production") {
        /* eslint-disable no-debugger, no-console */
        console.group("response");
        console.log(title);
        console.log(errors);
        console.groupEnd();
      }
    }

    if (status === 401) {
      sessionStorage.removeItem(USER_SESSION);
      router.push({ name: "home" });
    }

    if (status === 500) {
      //
    }
    throw error;
  }
};
