<template>
  <section class="negative-top">
    <div class="container px-5 pb-5 pt-3 bg-white t-b-o">
      <base-input
        v-if="!loading"
        class="search-input"
        :clearable="true"
        custom-class="size-lg"
        :model-value="modelValue"
        :placeholder="$t('searchCompany')"
        prepend-icon="bi-search"
        type="text"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
      <h6 v-if="!loading" class="font-alt fw-bold mt-3">
        {{ $t("selectCategory") }}:
      </h6>
      <div class="align-items-center justify-content-center">
        <BigLoader v-if="loading" class="mt-5" />
        <div v-else class="box row">
          <div
            v-for="(item, index) in types"
            :key="index"
            class="col-lg-3 col-md-4"
          >
            <div
              class="card mt-2"
              :class="[
                item.class,
                {
                  selected: selectedId === item.id,
                },
              ]"
              @click="onSelect(item)"
            >
              <div class="card-body text-capitalize fw-bold">
                <input
                  :checked="selectedId === item.id"
                  class="c-orange"
                  type="radio"
                />
                {{ $i18n.locale == "es" ? item.name : item.nameEN }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import BigLoader from "@/components/BigLoader.vue";
import { ref } from "vue";
export default {
  components: {
    BigLoader,
    BaseInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: null,
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedId = ref(null);
    const onSelect = (item) => {
      selectedId.value = selectedId.value !== item.id ? item.id : null;
      emit("category-change", selectedId.value);
    };

    return {
      onSelect,
      selectedId,
    };
  },
};
</script>
<style scoped>
.search-input {
  max-width: 500px;
}
/* For Mobile */
@media screen and (max-width: 540px) {
  .negative-top {
    margin-top: -114px;
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 999px) {
  .negative-top {
    margin-top: -140px;
  }
}

@media screen and (min-width: 1000px) {
  .negative-top {
    margin-top: -164px;
  }
}

.card {
  padding: 3px;
  cursor: pointer;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}

.card-body {
  font-size: 14px;
}
</style>
