import { apiTurnos } from "@/core/api/";
import { objectToFormData } from "@/core/helpers/file";

export const getAppointmentCancellationReasons = () =>
  apiTurnos.get(`api/public/appointment/cancellationreasons`);

export const getAppointmentValidation = (appointmentId, email) =>
  apiTurnos.get(`api/public/appointment/validation`, {
    params: {
      appointmentId,
      email,
    },
  });

export const getAppointmentInfo = (payload) =>
  apiTurnos.get(`api/public/appointment/info/${payload}`);

export const cancelAppointment = (payload) =>
  apiTurnos.post(`api/public/appointment/cancel`, payload);

export const getServiceConfiguration = (companyId) =>
  apiTurnos.get(`api/appointment/${companyId}/service/configuration`);

export const getServicesByCategoryId = (companyId, categoryId) =>
  apiTurnos.get(`api/appointment/${companyId}/${categoryId}/services`);

export const getSubServices = (companyId, serviceId) =>
  apiTurnos.get(
    `api/appointment/${companyId}/service/${serviceId}/subservices`
  );

export const getAvailableSpaces = (payload) =>
  apiTurnos.post("api/appointment/available/spaces", payload);

export const getDocuments = (serviceId) =>
  apiTurnos.get(`api/appointment/service/${serviceId}/documents`);

export const prereserve = (clientAppointmentId) =>
  apiTurnos.post(`api/appointment/${clientAppointmentId}/prereserve`);

export const confirmAppointment = (model) => {
  const payload = objectToFormData({
    ...model,
    specialField7: model.specialField7
      ? new Date(model.specialField7).toUTCString()
      : null,
  });

  return apiTurnos.post("/api/appointment/advance/confirm", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getTermsAndConditions = (serviceTypeId) =>
  apiTurnos.get(`api/appointment/termsandconditions/${serviceTypeId}`);
