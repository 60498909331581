<template>
  <div
    class="modal fade"
    :class="{ show: modalActive }"
    style="background: rgba(0, 0, 0, 0.7)"
    :style="{ display: modalActive ? 'block' : 'none' }"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("mediaConsentHeader") }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="modal-content" v-html="htmlContent" />

          <div class="col-12 my-2">
            <div class="terms">
              <label class="checkbox-inline">
                <BaseCheckbox
                  id="acceptTerms"
                  name="acceptTerms"
                  v-model="acceptTerms"
                  size="16px"
                  color="#000"
                  :weight="900"
                >
                  {{ $t("mediaConsent") }}
                </BaseCheckbox></label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-success"
            type="button"
            :disabled="!acceptTerms"
            @click="accept"
          >
            {{ $t("continue") }}
          </button>
          <button class="btn btn-outline-danger" type="button" @click="decline">
            {{ $t("decline") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { t } from "@/plugins/i18n";

export default {
  components: {
    BaseCheckbox,
  },
  props: {
    modalActive: {
      type: Boolean,
      default: false,
    },
    modalAccept: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#707070",
    },
    htmlContent: String,
  },
  setup(props, { emit }) {
    const acceptTerms = ref(false);

    const schema = computed(() => {
      return yup.object({
        acceptTerms: yup.boolean().required(),
      });
    });

    const { validate } = useForm({
      validationSchema: schema,
    });

    const accept = async () => {
      const { valid } = await validate();

      if (valid && acceptTerms.value) {
        emit("accept");
      }
    };

    const decline = () => {
      emit("decline");
    };

    const required = (value) => {
      if (!value) {
        return t("field-required");
      }
      return true;
    };

    return { acceptTerms, accept, decline, required };
  },
};
</script>
<style lang="scss" scoped>
.term-header {
  color: #ff8105;
  border-bottom: 1px solid #e0e0e0;
}
.term-content {
  margin: 6px 0px;
  .term-content-block {
    font-size: 14px;
    color: #1c1d21;
    margin-bottom: 18px;
  }
}
.modal-title {
  text-transform: uppercase;
}
.modal-content {
  border: 0px;
  padding: 1em;
}
.modal-footer {
  padding-top: 2em;
}
.checkbox-inline {
  cursor: pointer;
}
</style>
