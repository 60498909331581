import axios from "axios";

import { 
  requestInterceptor, responseInterceptor,
  applicationInsightsRequestInterceptor, applicationInsightsResponseInterceptor
} from './interceptor';

// eslint-disable-next-line no-undef
const _env = process.env;

export const apiTurnos = axios.create({
  baseURL: _env.VUE_APP_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 0, // TODO: Make this configurable
});

apiTurnos.interceptors.request.use(
  requestInterceptor.onFulfilled,
  requestInterceptor.onRejected
);

apiTurnos.interceptors.response.use(
  responseInterceptor.onFulfilled,
  responseInterceptor.onRejected
);

if(_env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY !== undefined) {
  apiTurnos.interceptors.request.use(
    applicationInsightsRequestInterceptor.onFulfilled,
    applicationInsightsRequestInterceptor.onRejected
  );

  apiTurnos.interceptors.response.use(
    applicationInsightsResponseInterceptor.onFulfilled,
    applicationInsightsResponseInterceptor.onRejected
  );
};
