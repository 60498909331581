<template>
  <section class="main-container" :style="style">
    <div class="container py-5 bg-white t-b-o">
      <h2 class="font-alt mb-4 fw-bold text-center main-title">
        {{ $t("cancel-appointment") }}
      </h2>
      <div
        class="align-items-center justify-content-center mt-5"
        :class="'ps-3 pe-3'"
      >
        <div class="d-md-flex justify-content-between">
          <div class="card-body text-capitalize">
            <div
              v-if="!isValid && !showSuccess"
              class="row justify-content-center"
            >
              <div class="col-lg-6 col-md-6 col-sm-12" :class="'ps-3 pe-3'">
                <v-form ref="formValidator">
                  <label class="label">{{ $t("email") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.email"
                      class="form-control"
                      name="email"
                      :placeholder="$t('email')"
                      :rules="isRequired"
                      type="email"
                    />
                    <span class="input-group-text">
                      <i class="far fa-calendar-alt" />
                    </span>
                  </div>
                  <error-message class="error" name="email" />
                  <label class="label">{{ $t("appointment-number") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.number"
                      class="form-control"
                      name="number"
                      :placeholder="$t('appointment-number')"
                      :rules="isRequired"
                      type="number"
                    />
                    <span class="input-group-text">
                      <i class="far fa-calendar-alt" />
                    </span>
                  </div>
                  <error-message class="error" name="number" />
                  <div
                    v-if="model.validation"
                    class="alert alert-danger custom-alert"
                  >
                    {{ model.validation }}
                  </div>
                </v-form>
              </div>

              <div class="row justify-content-center text-center mt-4">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <button
                    class="btn btn-continue mb-2"
                    type="button"
                    @click="getValidation()"
                  >
                    {{ $t("continue") }} <i class="bi bi-arrow-right-circle" />
                  </button>
                </div>
              </div>
            </div>
            <v-form ref="formInfoValidator">
              <big-loader v-if="loading" />
              <div
                v-if="isValid && !showSuccess && !loading"
                class="row justify-content-center"
              >
                <div
                  class="col-lg-6 col-md-6 col-sm-12"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <label class="label">{{ $t("name") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.name"
                      class="form-control"
                      name="name"
                    />
                  </div>

                  <label class="label">{{ $t("number") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.number"
                      class="form-control"
                      name="number"
                    />
                  </div>

                  <label class="label">{{ $t("cellphone") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.phone"
                      class="form-control"
                      name="phone"
                    />
                  </div>

                  <label class="label">{{ $t("dateTime") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.date"
                      class="form-control"
                      name="date"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-6 col-sm-12"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <label class="label">{{ $t("status") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.statusDesc"
                      class="form-control"
                      name="status"
                    />
                  </div>

                  <label class="label">{{ $t("physicalAddress") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.location"
                      class="form-control"
                      name="location"
                    />
                  </div>

                  <label class="label">{{ $t("service") }}: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.serviceDesc"
                      class="form-control"
                      name="service"
                    />
                  </div>

                  <label class="label">{{ $t("reason-cancellation") }}: </label>
                  <div class="mb-3 mt-1">
                    <v-select
                      v-model="model.cancelReason"
                      class="custom-vselect"
                      label="name"
                      :options="cancelReasons"
                      placeholder="Seleccione"
                    />
                    <v-field
                      v-show="false"
                      v-model="model.cancelReason"
                      class="form-control"
                      name="reason"
                      :rules="isRequired"
                    />
                    <error-message class="error" name="reason" />
                  </div>
                </div>
                <div
                  class="col-lg-12 col-md-12 col-sm-12 mt-3"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <span class="d-flex justify-content-between">
                    <button
                      class="btn btn-light mb-2"
                      type="button"
                      @click="model.isValid = !model.isValid"
                    >
                      <i class="bi bi-arrow-left-circle" /> {{ $t("goBack") }}
                    </button>
                    <button
                      class="btn btn-continue mb-2"
                      type="button"
                      @click="cancelConfirm()"
                    >
                      {{ $t("continue") }}
                      <i class="bi bi-arrow-right-circle" />
                    </button>
                  </span>
                </div>
              </div>
            </v-form>
            <div
              v-if="showSuccess"
              class="row justify-content-center align-items-center text-center"
            >
              <h5>{{ $t("appointment-cancelled") }}</h5>
              <div>
                <i class="fas fa-calendar" />
              </div>
              <p class="justify-content-center">
                <strong style="text-transform: none;">{{ $t("appointment-successfully-cancelled") }}</strong>
              </p>
              <div>
                <button
                  class="btn btn-continue mb-2"
                  type="button"
                  @click="goHome()"
                >
                  {{ $t("home") }} <i class="fas fa-circle-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  getAppointmentValidation,
  getAppointmentCancellationReasons,
  cancelAppointment,
} from "@/services/AppointmentService.js";
import { computed } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import BigLoader from "@/components/BigLoader.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { DESING_CONFIGURATION } from "@/constants/SessionStorage";

export default {
  components: {
    vSelect,
    vForm: Form,
    vField: Field,
    ErrorMessage: ErrorMessage,
    BigLoader,
  },
  data() {
    return {
      model: {
        ClientAppointmentId: 0,
        email: null,
        number: null,
        name: null,
        statusDesc: null,
        phone: null,
        date: null,
        location: null,
        serviceDesc: null,
        cancelReason: null,
        isValid: false,
        validation: "",
      },
      loading: false,
      categoryList: [
        {
          idType: 3,
          name: "Gobierno",
          class: "me-md-3",
          isSelected: false,
        },
        {
          idType: 108,
          name: "Consorcios",
          class: "",
          isSelected: false,
        },
        {
          idType: 109,
          name: "Municipios",
          class: "ms-md-3",
          isSelected: false,
        },
      ],
      cancelReasons: [],
      showSuccess: false,
    };
  },
  setup() {
    const designConfiguration = sessionStorage.getItem(DESING_CONFIGURATION)
      ? JSON.parse(sessionStorage.getItem(DESING_CONFIGURATION))
      : {};

    const style = computed(() => {
      return {
        "--title-size": designConfiguration?.contentTitleFontSize
          ? `${designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          designConfiguration?.contentTitleFontColor || "#171717",
        "--content-background-color": designConfiguration?.contentBackgroundColor || "rgba(233, 181, 99, 0.11)",
        "--control-text-color":
          designConfiguration?.formControlLabelColor || "#272727",
        "--secondary-color":
          designConfiguration?.contentTitleFontColor || "#f1b355",
        "--button-text-color":
          designConfiguration?.buttonFontColor || "#fff",
        "--button-background-color":
          designConfiguration?.buttonBackgroundColor || "#e9b563",
        "--button-border-color":
          designConfiguration?.buttonBorderColor || "#272727",
        "--button-text-color-active":
          designConfiguration?.buttonModalFontColor || "#fff",
        "--button-background-color-active":
          designConfiguration?.buttonModalBackgroundColor || "#c64819",
        "--button-border-color-active":
          designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    return {
      style,
    };
  },
  computed: {
    isValid() {
      return this.model.isValid;
    },
    isMobile() {
      return screen.width <= 760;
    },
  },
  async mounted() {
    const { email, appointment } = this.$route.query;
    if (email && appointment) {
      this.model.email = email;
      this.model.number = appointment;
    }
    document.querySelector("body").style.backgroundColor =
      "rgb(233 181 99 / 11%)";
    await this.getCancellationReasons();
  },
  watch: {
    "$i18n.locale"() {
      this.updateCancelReasonsLang();
    },
  },
  methods: {
    select(item) {
      this.unselectAll();
      item.isSelected = true;
      this.$emit("selected", item);
    },
    unselectAll() {
      this.categoryList.map((i) => {
        return (i.isSelected = false);
      });
    },
    async getValidation() {
      const isFormValid = await this.$refs.formValidator.validate();
      if (!isFormValid.valid) {
        return;
      }

      await getAppointmentValidation(this.model.number, this.model.email).then(
        async (resp) => {
          this.model = { ...this.model, ...resp.data };
        }
      );
    },
    updateCancelReasonsLang() {
      this.model.cancelReason = null;
      this.cancelReasons = this.cancelReasons
        .map((x) => {
          return {
            ...x,
            name: this.$i18n.locale == "es" ? x.nameES : x.nameEN,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    async getCancellationReasons() {
      await getAppointmentCancellationReasons().then(async (resp) => {
        this.cancelReasons = resp.data.map((x) => ({ ...x, nameES: x.name }));
        this.updateCancelReasonsLang();
      });
    },
    isRequired(value) {
      if (!value) {
        return this.$t("field-required");
      }
      return true;
    },
    goHome() {
      this.$router.goHome();
    },
    async cancelConfirm() {
      const formValidator = await this.$refs.formInfoValidator.validate();
      if (!formValidator.valid) {
        return;
      }
      this.loading = true;
      await cancelAppointment({
        clientAppointmentId: this.model.number,
        reason: this.model.cancelReason?.id,
        comment: null,
      })
        .then(() => {
          this.showSuccess = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>

.main-container {
  background-color: var(--content-background-color);
}
.main-title {
  font-size: var(--title-size);
  color: var(--title-color);
}
.label {
  color: var(--control-text-color);
  text-align: left;
  font: normal normal 600 14px/21px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

.t-b-o {
  border-top-color: var(--secondary-color);
}
.input-group-text {
  background-color: transparent !important;
  border: none;
  border-bottom: 2px var(--secondary-color) solid;
}

.form-control,
.form-control:focus {
  border: none;
  border-bottom: 2px var(--secondary-color) solid;
  border-radius: 0%;
  padding-left: 0px;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.custom-vselect {
  background-color: transparent !important;
  border: none;
  border-bottom: 2px var(--secondary-color) solid;
}

.card {
  width: 100%;
  padding: 3px;
  cursor: pointer;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}

.btn-coordinate {
  background-color: #e06537;
  color: #fff;
}

.btn-coordinate:hover {
  background-color: #c64819;
  color: #fff;
}

.btn-continue {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

.btn-continue:hover {
  background-color: var(--button-background-color-active);
  color: var(--button-text-color-active);
}

.custom-alert {
  font: normal normal 600 14px/21px Montserrat;
  text-transform: none !important;
}

.error {
  color: red;
  font: normal normal 600 14px/21px Montserrat;
  text-transform: none !important;
  display: block;
}

.text-center {
  text-align: center;
}

* >>> {
  --vs-controls-color: var(--secondary-color);
  --vs-border-style: none;
  --vs-dropdown-option--active-bg: var(--secondary-color);
}
</style>
