<template>
  <section class="bg-white t-b-o">
    <div class="container px-5">
      <h4 class="font-alt mb-4">Preguntas Frecuentes</h4>
      <div class="align-items-center justify-content-center">
        <div id="accordionFQA" class="accordion">
          <div class="accordion-item">
            <h2 id="faq-1" class="accordion-header">
              <button
                aria-controls="collapseFaq-1"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-1"
                data-bs-toggle="collapse"
                type="button"
              >
                1. {{ $t("faq.how-to-navigate") }}
              </button>
            </h2>
            <div
              id="collapseFaq-1"
              aria-labelledby="faq-1"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                {{ $t("faq.how-to-navigate-response") }}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-2" class="accordion-header">
              <button
                aria-controls="collapseFaq-2"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-2"
                data-bs-toggle="collapse"
                type="button"
              >
                2. {{ $t("faq.how-to-request-the-appointment") }}
              </button>
            </h2>
            <div
              id="collapseFaq-2"
              aria-labelledby="faq-2"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                {{ $t("faq.how-to-request-the-appointment-response") }}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-3" class="accordion-header">
              <button
                aria-controls="collapseFaq-3"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-3"
                data-bs-toggle="collapse"
                type="button"
              >
                3. {{ $t("faq.can-request-office") }}
              </button>
            </h2>
            <div
              id="collapseFaq-3"
              aria-labelledby="faq-3"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                {{ $t("faq.can-request-office-response") }}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-4" class="accordion-header">
              <button
                aria-controls="collapseFaq-4"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-4"
                data-bs-toggle="collapse"
                type="button"
              >
                4. {{ $t("faq.appointment-the-hour-by-order-of-arrival") }}
              </button>
            </h2>
            <div
              id="collapseFaq-4"
              aria-labelledby="faq-4"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                {{
                  $t("faq.appointment-the-hour-by-order-of-arrival-response")
                }}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-5" class="accordion-header">
              <button
                aria-controls="collapseFaq-5"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-5"
                data-bs-toggle="collapse"
                type="button"
              >
                5. {{ $t("faq.can-cancel-appointment") }}
              </button>
            </h2>
            <div
              id="collapseFaq-5"
              aria-labelledby="faq-5"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                {{ $t("faq.can-cancel-appointment-response") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped>
.accordion-item {
  border: 1px solid #f8dfb7 !important;
}
.text-justify {
  text-align: justify;
}
</style>
