import { createI18n } from "vue-i18n";
import messages from "../translations";

sessionStorage.setItem('locale', 'es');

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  legacy: false,
  globalInjection: true,
  messages,
});

export default i18n;

export const t = (key, args = {}) => {
  return i18n.global.t(key, args);
};
