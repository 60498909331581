import { ref } from "vue";

export function useGeolocation() {
  const currentLocation = ref(null);

  const getCurrentLocation = new Promise((resolve) => {
    let position = {
      latitude: null,
      longitude: null,
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Geolocation allowed
        (pos) => {
          position.latitude = pos.coords.latitude;
          position.longitude = pos.coords.longitude;
          currentLocation.value = position;
          resolve(position);
        },
        // Geolocation denied
        () => {
          currentLocation.value = null;
          resolve(null);
        }
      );
    } else {
      // Not supported? Allow
      currentLocation.value = null;
      resolve(null);
    }
  });

  const getDistance = (lat, lon, unit = "K") => {
    if (!currentLocation.value || !lat || !lon) return null;
    if (
      currentLocation.value.latitude == lat &&
      currentLocation.value.longitude == lon
    )
      return 0;

    var radlat1 = Math.PI * (currentLocation.value.latitude / 180);
    var radlat2 = (Math.PI * lat) / 180;
    var theta = currentLocation.value.longitude - lon;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist.toFixed(2);
  };

  return {
    getCurrentLocation,
    getDistance,
    currentLocation,
  };
}
