<template>
  <div
    class="modal fade"
    :class="{ show: modalActive }"
    style="background: rgba(0, 0, 0, 0.7)"
    :style="{ display: modalActive ? 'block' : 'none' }"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content">
            <div class="row data">
              <div class="col-sm-12">
                <label>{{$t('yourAppointmentNumberIs')}}: {{ appointment.clientAppointmentId }}</label>
              </div>
            </div>
            <div class="row warning">
              <label>{{ $t('stampsWarning') }}</label>
            </div>
            <div class="row data">
              <div class="col-xs-12 col-sm-12 col-md-3">
                <label>{{$t('client')}}</label>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9">
                <label>{{ appointment.clientName }}</label>
              </div>
            </div>
            <div class="row data">
              <div class="col-xs-12 col-sm-12 col-md-3">
                <label>{{$t('office')}}</label>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9">
                <label>{{ appointment.locationName }}</label>
              </div>
            </div>
            <div class="row data">
              <div class="col-xs-12 col-sm-12 col-md-3">
                <label>{{$t('service')}}</label>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9">
                <label>{{ appointment.serviceTypeName }}</label>
              </div>
            </div>
            <div class="row data">
              <div class="col-xs-12 col-sm-12 col-md-3">
                <label>{{$t('dateTime')}}</label>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9">
                <label>{{ formatDate(appointment.timeStart) }}</label>
              </div>
            </div>
            <div class="row data">
              <div class="col-xs-12 col-sm-12 col-md-3">
                <label>{{$t('attendees')}}</label>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9">
                <label>{{ appointment.attendees }}</label>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-sm-12 col-lg-2 m-auto text-center">
                <img class="p-2" :src="colecturiaLogo" />
              </div>
              <div class="col-sm-12 col-lg-7">
                <label class="disclaimer p-2">{{$t('stampsValidationMessage')}}</label>
              </div>
              <div class="col-sm-12 col-lg-3 m-auto text-center">
                <button
                  class="btn btn-success p-2"
                  type="button"
                  @click="goToPaymentSite"
                >
                  {{ $t("buyStamps") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import colecturiaLogo from "@/assets/img/colecturia.png";
import { t } from "@/plugins/i18n";

export default {
  components: {
  },
  props: {
    modalActive: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      value: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: "#707070",
    },
  },
  setup(props) {
    const goToPaymentSite = () => {
        window.location = props.appointment.stampPaymentUrl;
      }

    const formatDate = (payload) => {
      const date = new Date(payload);
      return `${date.toLocaleDateString(t.locale == 'es' ? 'es' : 'en')} ${date.toLocaleTimeString(t.locale == 'es' ? 'es' : 'en')}`;
    };

    return { 
      colecturiaLogo,
      goToPaymentSite,
      formatDate
    };
  },
};
</script>
<style lang="scss" scoped>
.term-header {
  color: #ff8105;
  border-bottom: 1px solid #e0e0e0;
}
.term-content {
  margin: 6px 0px;
  .term-content-block {
    font-size: 14px;
    color: #1c1d21;
    margin-bottom: 18px;
  }
}
.modal-title {
  text-transform: uppercase;
  margin: auto;
}
.modal-content {
  border: 0px;
  padding: 1em;
  font-weight: lighter;

  .warning {
    padding: 0.8rem 0;
    background-color: #f08080;
    color: white;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.7rem;
  }

  .disclaimer {
    text-align: justify;
  }

  .data {
    padding: 0.8rem 0;
    border-bottom: 1px solid #e0e0e0;
  }
}
.modal-footer {
  padding-top: 2em;
}
.checkbox-inline {
  cursor: pointer;
}
</style>
