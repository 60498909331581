<template>
  <section class="negative-top">
    <div class="container px-5 pt-3 pb-5 bg-white">
      <div class="row">
        <div class="col-md-6">
          <h6 class="font-alt fw-bold mt-2">{{ $t("selectCompany") }}:</h6>
        </div>
      </div>
      <BigLoader v-if="loading" />
      <div v-else class="d-flex align-items-center swiper-container">
        <button
          v-if="swiperRef && !swiperRef.isBeginning"
          class="btn btn-outline-primary action-button prev"
          @click="prepend()"
        >
          <i class="bi bi-chevron-compact-left" />
        </button>
        <button
          v-if="swiperRef && !swiperRef.isEnd"
          class="btn btn-outline-primary action-button next"
          @click="append()"
        >
          <i class="bi bi-chevron-compact-right" />
        </button>
        <swiper
          class="mySwiper"
          :modules="modules"
          :pagination="{
            clickable: true,
          }"
          :slidesPerView="slidesPerView"
          :spaceBetween="spaceBetween"
          @swiper="setSwiperRef"
        >
          <swiper-slide v-for="(option, index) in options" :key="index">
            <div class="row">
              <div v-for="(item, i) in option" :key="i" class="col-md-12 mb-2">
                <div
                  class="card card-company"
                  :class="[
                    item.class,
                    { 'company-selected': selectedCompany == item },
                  ]"
                  @click="login(item)"
                >
                  <div class="company-header">
                    {{
                      selectedCompany === item
                        ? `${$t("loading")}...`
                        : item.name
                    }}
                  </div>
                  <div class="company-img">
                    <img
                      v-if="item.logoFile"
                      class="slider-card-img-top"
                      :src="item.logoFile.publicUrl"
                    />
                    <img
                      v-else
                      class="slider-card-img-top"
                      src="../../../assets/img/no-logo.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { getById as _getPlusClientById } from "@/services/PlusClientService";
import { login as _login } from "@/services/AccountService";
import { ref } from "vue";
import { useRouter } from "vue-router";
import BigLoader from "@/components/BigLoader.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

import {
  USER_SESSION,
  PLUS_CLIENT,
  DESING_CONFIGURATION,
} from "@/constants/SessionStorage";

export default {
  components: {
    BigLoader,
    Swiper,
    SwiperSlide,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const selectedCompany = ref(null);
    const router = useRouter();

    const login = (company) => {
      if (!company) return;

      if(company.isPremium && company.premiumSiteUrl) {
        window.location = company.premiumSiteUrl;
        return;
      }

      selectedCompany.value = company;

      _login({ companyId: company.id })
        .then(async ({ data }) => {
          if (!data) return;

          sessionStorage.setItem(USER_SESSION, JSON.stringify(data));

          let desingConfiguration = null;

          if (company.plusClientId) {
            const { data: plusClient } = await _getPlusClientById(company.plusClientId);
            sessionStorage.setItem(PLUS_CLIENT, JSON.stringify(plusClient));
            desingConfiguration =
              plusClient.plusClientLayoutDesignConfigurations[0];
          }

          sessionStorage.setItem(
            DESING_CONFIGURATION,
            JSON.stringify(desingConfiguration)
          );
          
          router.push({ name: "createAppointment" });
        })
        .finally(() => (selectedCompany.value = null));
    };

    return {
      login,
      selectedCompany,
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
    };
  },
  data() {
    return {
      items: [],
      currentItem: null,
      swiperRef: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    slidesPerView() {
      if (this.windowWidth <= 540) return 1;
      if (this.windowWidth <= 767) return 2;

      if (this.windowWidth <= 1200) return 3;

      return 4;
    },
    spaceBetween() {
      // if (this.windowWidth <= 767) return 10;

      // if (this.windowWidth <= 1200) return 20;

      return 10;
    },
    options() {
      return this.groupByN(3, this.companies);
    },
  },
  async mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    setSwiperRef(swiper) {
      this.swiperRef = swiper;
    },
    append() {
      this.swiperRef.slideNext();
    },
    prepend() {
      this.swiperRef.slidePrev();
    },
    groupByN(n, arr) {
      let result = [];
      for (let i = 0; i < arr.length; i += n) result.push(arr.slice(i, i + n));
      return result;
    },
  },
};
</script>
<style scoped>
.company-header {
  font-size: 12px;
  border-bottom: 1px solid #e9b563;
  height: 37px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.swiper-container {
  position: relative;
}
.card {
  width: 100%;
  padding: 3px;
  display: block;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

/* .card:hover {
  margin-top: -10px;
  transition: 0.3s ease-in-out;
} */

.card.company-selected {
  border: solid 2px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}

.swiper {
  width: 92%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.swiper-pagination {
  margin-top: 20px;
}

::v-deep .swiper-pagination-bullet-active {
  background: #ffa21a;
}

.action-button {
  color: #ffa21a;
  border-color: #ffa21a;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}

.action-button > i {
  font-size: 32px;
}

.next {
  right: -20px;
}
.prev {
  left: -20px;
}
.action-button:hover {
  background: #ffa21a;
  border-color: #ffa21a;
  box-shadow: none;
}
.action-button:focus,
.action-button:active {
  background: #ffa21a;
  border-color: #ffa21a;
  color: white;
  box-shadow: none;
}
.action-button:disabled {
  border-color: grey;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.company-img {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100% - 35px);
}

.slider-card-img-top {
  max-height: 100px;
}

.swiper-slide img {
  display: block;
  width: 80%;
  align-self: center;
  justify-self: center;
  /* object-fit: cover; */
}

.card-company {
  height: 150px;
  min-width: 150px;
  cursor: pointer;
}

.actions-container {
  background-color: #f7f7f7;
  border: none;
}

/* For Mobile */
@media screen and (max-width: 540px) {
  .negative-top {
    margin-top: -114px;
  }
  .action-button {
    height: 50px;
    width: 50px;
  }
  .action-button > i {
    font-size: 27px;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 999px) {
  .negative-top {
    margin-top: -140px;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}

@media screen and (min-width: 1000px) {
  .negative-top {
    margin-top: -184px;
  }
}
</style>
