<template>
  <div class="row m-0" :style="style">
    <div
      class="col-lg-12"
      :style="{
        'background-color':
          designConfiguration?.headerBackgroundColor || '#ffa21a',
      }"
    >
      <Stepper
        v-if="showHorizontalStep"
        class="horizontal-stepper"
        :design-configuration="designConfiguration"
        :steps="steps"
        :value="currentStep"
      />
      <VerticalStepper
        v-else
        class="vertical-stepper"
        :design-configuration="designConfiguration"
        :steps="steps"
        :value="currentStep"
      />
    </div>
    <div
      v-if="currentStep > 1"
      class="col-lg-12 header pt-4"
      :style="{
        'background-color':
          designConfiguration?.contentBackgroundColor || 'white',
      }"
    >
      <div v-if="currentStep == 6" class="container mb-4">
        <div class="title">{{ $t("confirm-appointment-contact") }}</div>
        <div class="subtitle">
          {{ $t("information-selected-appointment") }}:
        </div>
      </div>
      <div v-if="currentStep !== 1 && currentStep !== 7" class="container">
        <AppointmentStepHeader
          :current-step="currentStep"
          :design-configuration="designConfiguration"
          :documents="answer.documents"
          :location="answer.location"
          :service="answer.service"
          :space="answer.space"
        />
      </div>
    </div>
    <div
      class="main-content col-lg-12"
      :style="{
        'background-color':
          designConfiguration?.contentBackgroundColor || 'white',
      }"
    >
      <ServiceScreen
        v-if="currentStep == 1"
        v-model:answer="answer.service"
        v-model:configuration="configuration.service"
        :design-configuration="designConfiguration"
        :profile="profile"
        @next="currentStep = 2"
      />
      <LocationScreen
        v-if="currentStep == 2"
        v-model:answer="answer.location"
        v-model:mediaConsent="answer.mediaConsent"
        v-model:configuration="configuration.locations"
        :design-configuration="designConfiguration"
        :profile="profile"
        :service="answer.service"
        @back="goBackToService()"
        @next="currentStep = 3"
      />
      <DateTimeScreen
        v-if="currentStep == 3"
        v-model:answer="answer.space"
        :design-configuration="designConfiguration"
        :location="answer.location"
        :profile="profile"
        :service="answer.service"
        :plus-client="plusClient"
        @back="goBackToLocation()"
        @next="currentStep = (answer.service.value.canAttachDocument && !answer.location.hideAttachDocument) ? 4 : 5"
      />
      <DocumentScreen
        v-if="currentStep == 4"
        v-model:answer="answer.documents"
        :design-configuration="designConfiguration"
        :profile="profile"
        :service="answer.service"
        @back="goBackToDatetime()"
        @next="currentStep = 5"
      />
      <PersonalInfoScreen
        v-if="currentStep == 5"
        v-model:answer="answer.personalInfo"
        v-model:configuration="configuration.personalInfo"
        :design-configuration="designConfiguration"
        :location="answer.location"
        :service="answer.service"
        :plus-client="plusClient"
        :profile="profile"
        :metadata="metadata"
        @back="
          (answer.service.value.canAttachDocument && !answer.location.hideAttachDocument)
            ? goBackToDocument()
            : goBackToDatetime()
        "
        @next="currentStep = 6"
      />
      <ConfirmationScreen
        v-if="currentStep == 6"
        v-model:confirmation="answer.confirmation"
        :answer="answer"
        :configuration="configuration.personalInfo"
        :design-configuration="designConfiguration"
        :personal-info="answer.personalInfo"
        :plus-client="plusClient"
        :profile="profile"
        @back="currentStep = 5"
        @next="currentStep = 7"
      />
      <ConfirmedScreen
        v-if="currentStep == 7"
        :answer="answer"
        :appointment="answer.confirmation"
        :configuration="configuration"
        :design-configuration="designConfiguration"
        :plus-client="plusClient"
        :profile="profile"
      />
    </div>
  </div>
</template>

<script>
import Stepper from "./components/Stepper.vue";
import VerticalStepper from "./components/VerticalStepper.vue";
import ServiceScreen from "./components/screens/ServiceScreen.vue";
import LocationScreen from "./components/screens/LocationScreen.vue";
import DateTimeScreen from "./components/screens/DateTimeScreen.vue";
import DocumentScreen from "./components/screens/DocumentScreen.vue";
import PersonalInfoScreen from "./components/screens/PersonalInfoScreen.vue";
import ConfirmationScreen from "./components/screens/ConfirmationScreen.vue";
import ConfirmedScreen from "./components/screens/ConfirmedScreen.vue";
import {
  USER_SESSION,
  DESING_CONFIGURATION,
  PLUS_CLIENT,
} from "@/constants/SessionStorage";
import {
  ref,
  reactive,
  watch,
  defineComponent,
  computed,
  onMounted,
} from "vue";
import AppointmentStepHeader from "../../components/AppointmentStepHeader.vue";

export default defineComponent({
  name: "CreateAppointment",
  components: {
    Stepper,
    ServiceScreen,
    LocationScreen,
    AppointmentStepHeader,
    DateTimeScreen,
    DocumentScreen,
    PersonalInfoScreen,
    ConfirmationScreen,
    ConfirmedScreen,
    VerticalStepper,
  },
  setup() {
    const search = ref("");
    const windowWidth = ref(window.innerWidth);
    const configuration = reactive({
      service: null,
      locations: [],
      personalInfo: null,
    });
    const answer = reactive({
      service: null,
      location: null,
      space: null,
      documents: null,
      personalInfo: {},
      confirmation: null,
      mediaConsent: null,
    });
    const userSession = sessionStorage.getItem(USER_SESSION);
    const profile = JSON.parse(userSession).profile;
    const metadata = JSON.parse(userSession).metadata;

    const designConfiguration = sessionStorage.getItem(DESING_CONFIGURATION)
      ? JSON.parse(sessionStorage.getItem(DESING_CONFIGURATION))
      : {};

    const plusClient = sessionStorage.getItem(PLUS_CLIENT)
      ? JSON.parse(sessionStorage.getItem(PLUS_CLIENT))
      : {};

    const categories = ref([]);
    const steps = computed(() => {
      return [
        { id: 1, name: "service" },
        { id: 2, name: "location" },
        { id: 3, name: "dateTime" },
        {
          id: 4,
          name: "documents",
          disabled: !answer.service?.value?.canAttachDocument,
        },
        { id: 5, name: "personalInformation" },
        { id: 6, name: "confirmation" },
      ];
    });
    const currentStep = ref(1);

    window.addEventListener("resize", () => {
      windowWidth.value = window.innerWidth;
    });

    watch(
      () => answer.service,
      () => {
        configuration.locations = [];
      }
    );

    const goBackToService = () => {
      currentStep.value = 1;
      answer.service = null;
    };
    const goBackToLocation = () => {
      currentStep.value = 2;
      answer.location = null;
    };
    const goBackToDatetime = () => {
      currentStep.value = 3;
      answer.space = null;
      answer.documents = [];
    };

    const goBackToDocument = () => {
      currentStep.value = 4;
    };

    onMounted(() => {
      if (designConfiguration) {
        document.querySelector("body").style.backgroundColor =
          designConfiguration.contentPageBackgroundColor;
      }
    });

    const style = computed(() => {
      return {
        "--title-color":
          designConfiguration?.contentTitleFontColor || "#171717",
      };
    });

    const showHorizontalStep = computed(() => windowWidth.value > 530);

    return {
      steps,
      currentStep,
      search,
      categories,
      profile,
      metadata,
      configuration,
      answer,
      goBackToDocument,
      designConfiguration,
      style,
      goBackToDatetime,
      goBackToService,
      goBackToLocation,
      plusClient,
      showHorizontalStep
    };
  },
});
</script>
<style lang="scss">
.stepper {
  background-color: #ffa21a;
}
.header {
  background-color: white;
}

.main-content {
  min-height: 600px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: var(--title-color);
}
.subtitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--title-color);
}
</style>
