<template>
  <div
    class="form-check"
    :style="{ '--color-checked': colorChecked || '#f3aa1c' }"
  >
    <input
      :id="id"
      v-model="inputValue"
      class="form-check-input"
      :class="{ 'is-invalid': !!errorMessage }"
      :disabled="disabled"
      :style="{
        fontWeight: `${fontWeight} !important`,
        fontSize: `${fontSize} !important`,
        color: `${fontColor} !important`,
      }"
      type="checkbox"
      @blur="handleBlur"
      @input="onChange"
    />
    <label
      class="form-check-label"
      :for="id"
      :style="{
        fontWeight: `${fontWeight} !important`,
        fontSize: `${fontSize} !important`,
        color: `${fontColor} !important`,
      }"
      v-html="label"
    />
    <span
      :style="{
        color: color,
        fontSize: size || 25,
      }"
    >
      <slot />
    </span>
    <div
      v-if="!!errorMessage"
      class="invalid-feedback"
      :style="{
        fontSize: `${fontSize} !important`,
      }"
    >
      {{ $t("field-required") }}
    </div>
  </div>
</template>
<script>
import { computed, toRef } from "vue";
import { useField } from "vee-validate";

export default {
  name: "BaseCheckbox",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: Number,
      default: 400,
    },
    size: {
      type: String,
      default: "12pt",
    },
    color: {
      type: String,
      default: "#707070",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    colorChecked: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const fontWeight = computed(() => props.weight || 400);
    const fontSize = computed(() => props.size || "14pt");
    const fontColor = computed(() => props.color || "#707070");

    const name = toRef(props, "name");

    const onChange = (event) => {
      inputValue.value = event.target.value == "on";
    };

    const {
      value: inputValue,
      meta,
      errorMessage,
      handleChange,
      handleBlur,
    } = useField(name, undefined, {
      initialValue: props.checked,
    });

    return {
      fontWeight,
      fontSize,
      fontColor,
      inputValue,
      errorMessage,
      meta,
      onChange,
      handleChange,
      handleBlur,
    };
  },
};
</script>
<style scoped>
.form-check-input:checked {
  background-color: var(--color-checked) !important;
  border-color: #ffffff;
}
</style>
