<template>
  <div class="container py-3" :style="style">
    <div
      v-for="(step, i) in steps"
      :key="i"
      class="step"
      :class="{
        finished: step.id <= value,
        active: step.id === value,
        disabled: step.disabled,
      }"
    >
      <div>
        <div class="circle">{{ step.id }}</div>
      </div>
      <div>
        <div class="title">{{ $t(step.name) }}</div>
        <div class="line" />
        <small v-if="step.disabled" style="font-size: 12px">
          {{ $t("no-required") }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, computed } from "vue";

export default defineComponent({
  name: "VerticalStepperComponent",
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props) {
    watch(
      () => props.value,
      () => {
        setTimeout(() => {
          const element = document.querySelector(".active");
          if (element)
            element.scrollIntoView({ block: "center", behavior: "smooth" });
        }, 200);
      }
    );

    const style = computed(() => {
      return {
        "--header-background-color":
          props.designConfiguration?.tableHeaderBackgroundColor || "#ffa21a",
        "--header-text-color":
          props.designConfiguration?.tableHeaderFontColor || "#ffe2bb",
        "--header-completed-color":
          props.designConfiguration?.tableCompletedIconColor || "#272727",
      };
    });
    return {
      style,
    };
  },
});
</script>
<style lang="scss" scoped>
/* Steps */

.container {
  height: 150px;
  overflow-y: auto;
}
.step {
  position: relative;
  min-height: 1em;
  height: 40px;
}
.step + .step {
  margin-top: 1em;
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-left: 2.5em;
  padding-left: 1em;
}

/* Circle */
.circle {
  background-color: var(--header-text-color);
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 100%;
  color: var(--header-background-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 2px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: var(--header-text-color);
}
.step:last-child .circle:after {
  display: none;
}

/* Stepper Titles */
.title {
  line-height: 1.6em;
  font-size: 14px;
  color: var(--header-text-color);
  font-weight: 600;
}

.line {
  height: 0;
  border: 1px solid var(--header-text-color);
  width: 50%;
}
.caption {
  font-size: 0.8em;
}

.finished,
.active {
  .circle {
    background-color: var(--header-completed-color);
  }
  .title {
    color: var(--header-completed-color);
  }
  .line {
    border-color: var(--header-completed-color);
  }
}
.disabled {
  .circle {
    background-color: #dddddd;
    color: #f5f5f5;
  }

  .title {
    color: #dddddd;
  }
  .line {
    border-color: #dddddd;
  }
}
</style>
