<template>
  <div>
    <label
      class="form-label"
      :for="`input-${label}`"
      :style="{
        fontWeight: `${fontWeight} !important`,
        fontSize: `${fontSize} !important`,
        color: `${fontColor} !important`,
      }"
    >
      {{ label }}:
    </label>

    <VueDatepicker
      :id="`input-${id}`"
      v-model="inputValue"
      :autoApply="true"
      :cancelText="$t('cancel')"
      :class="{ 'is-invalid': Boolean(errorMessage) }"
      :clearable="clearable"
      :enable-time-picker="false"
      :error="errorMessage != null"
      format="dd/MMMM/yyyy"
      :format-locale="$i18n.locale == 'es' ? es : enUS"
      input-size="sm"
      :locale="$i18n.locale == 'es' ? 'es' : 'en'"
      :max-date="maxDate"
      :min-date="minDate"
      :name="name"
      :no-label="true"
      :noClearButton="true"
      :now-button-label="todayText"
      preview-format="dd/MMMM/yyyy"
      :selectText="$t('select')"
      :startDate="startDate"
      :style="{ fontSize: `${fontSize} !important` }"
      :yearRange="yearRange"
      @input="handleChange"
    />
  </div>
</template>
<script>
import { computed, toRef, defineComponent } from "vue";
import { useField } from "vee-validate";
import { t } from "@/plugins/i18n";
import { enUS, es } from "date-fns/locale";
import VueDatepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  components: {
    VueDatepicker,
  },
  props: {
    id: {
      type: String,
      default: "datepicker",
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    weight: {
      type: Number,
      default: 400,
    },
    size: {
      type: String,
      default: "12pt",
    },
    modelValue: {
      type: [Date, String],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    maxDate: {
      type: [Date, String],
      default: null,
    },
    minDate: {
      type: [Date, String],
      default: null,
    },
    startDate: {
      type: [Date, String],
      default: null,
    },
    yearRange: {
      type: Array,
      default: () => [1900, 2100],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#272727",
    },
  },
  setup(props) {
    const fontWeight = computed(() => props.weight || 400);
    const fontSize = computed(() => props.size || "14px");
    const fontColor = computed(() => props.color || "#272727");
    const todayText = computed(() => t("today"));

    const name = toRef(props, "name");

    const {
      value: inputValue,
      errorMessage,
      meta,
      handleChange,
    } = useField(name, undefined, {
      initialValue: props.modelValue,
    });

    return {
      fontWeight,
      fontSize,
      fontColor,
      inputValue,
      handleChange,
      errorMessage,
      meta,
      todayText,
      enUS,
      es,
    };
  },
});
</script>
<style lang="scss" scoped>
.invalid-feedback {
  display: block;
  position: absolute;
  max-width: 400px;
  margin-top: -21px;
}
.is-invalid::v-deep {
  input {
    border: 1px solid #dc3545;
  }
}
.form-label {
  margin-bottom: 0;
}
</style>
<style>
.dp__main input {
  background-color: white;
}
</style>
