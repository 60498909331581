<template>
  <div class="container mt-3 mb-5" :style="style">
    <div class="row">
      <div class="col-md-12 d-flex">
        <i class="bi bi-calendar-check header-icon" />
        <div
          class="header ps-4"
          :style="{
            'background-color':
              designConfiguration?.contentBackgroundColor || 'white',
          }"
        >
          <div class="title">{{ $t("confirmed-appointment") }}</div>
          <div class="subtitle">
            {{ $t("appointment-confirmation-number") }}:
            <div class="appointment-number">
              {{ appointment.clientAppointmentId }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <AppointmentStepHeader
          :current-step="6"
          :design-configuration="designConfiguration"
          :documents="answer.documents"
          :location="answer.location"
          :service="answer.service"
          :space="answer.space"
        />
      </div>
      <div class="col-md-12 info mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-6 py-3">
              <h6 class="mb-1">{{ $t("name") }}:</h6>
              <span class="info-subtitle">{{ appointment.clientName }}</span>
            </div>
            <div class="col-md-6 py-3">
              <h6 class="mb-1">{{ $t("email") }}:</h6>
              <span class="info-subtitle">{{ appointment.clientEmail }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 info mt-3">
        <div class="container">
          <div class="row">
            <div v-if="showTelephone" class="col-md-4 py-3">
              <h6 class="mb-1">{{ $t("cellphone") }}:</h6>
              <span class="info-subtitle">{{ cellphone }}</span>
            </div>
            <div v-if="showTelephone" class="col-md-4 py-3">
              <h6 class="mb-1">{{ $t("provider") }}:</h6>
              <span class="info-subtitle">{{ provider }}</span>
            </div>
            <div class="col-md-4 py-3" v-if='(plusClient && plusClient.showAttendeesNumber !== undefined) ? plusClient.showAttendeesNumber : true'>
              <h6 class="mb-1">{{ $t("attendees") }}:</h6>
              <span class="info-subtitle">{{ appointment.attendees }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSpecialFields" class="col-md-12 info mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mt-3">
              <div class="info-title">{{ $t("additional-information") }}</div>
              <hr :style="{ color: style['--control-text-color'] }" />
            </div>
            <div
              v-if="
                (specialFields.specialField1Enabled &&
                    specialFields.specialField1ShowAptsWeb) ||
                serviceSpecialFields.specialField1Enabled
              "
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  serviceSpecialFields.specialField1Name ||
                  specialFields.specialField1Name ||
                  `${$t("special-field")} #1`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ answer.personalInfo.specialField1 }}
              </span>
            </div>
            <div
              v-if="specialFields.specialField2Enabled &&
                    specialFields.specialField2ShowAptsWeb"
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  specialFields.specialField2Name ||
                  `${$t("special-field")} #2`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ answer.personalInfo.specialField2 }}
              </span>
            </div>
            <div
              v-if="specialFields.specialField3Enabled &&
                    specialFields.specialField3ShowAptsWeb"
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  specialFields.specialField3Name ||
                  `${$t("special-field")} #3`
                }}:
              </h6>
              <span class="info-subtitle">{{ specialField3 }}</span>
            </div>
            <div
              v-if="specialFields.specialField4Enabled &&
                    specialFields.specialField4ShowAptsWeb"
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  specialFields.specialField4Name ||
                  `${$t("special-field")} #4`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ answer.personalInfo.specialField4 }}
              </span>
            </div>
            <div
              v-if="specialFields.specialField5Enabled &&
                    specialFields.specialField5ShowAptsWeb"
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  specialFields.specialField5Name ||
                  `${$t("special-field")} #5`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ answer.personalInfo.specialField5 }}
              </span>
            </div>
            <div
              v-if="specialFields.specialField6Enabled &&
                    specialFields.specialField6ShowAptsWeb"
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  specialFields.specialField6Name ||
                  `${$t("special-field")} #6`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ answer.personalInfo.specialField6 }}
              </span>
            </div>
            <div
              v-if="
                (specialFields.specialField7Enabled  &&
                    specialFields.specialField7ShowAptsWeb) ||
                serviceSpecialFields.specialField7Enabled
              "
              class="col-md-4 py-3"
            >
              <h6 class="mb-1">
                {{
                  serviceSpecialFields.specialField7Name ||
                  specialFields.specialField7Name ||
                  `${$t("special-field")} #7`
                }}:
              </h6>
              <span class="info-subtitle">
                {{ specialField7 }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="documents.length" class="col-md-12 info mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mt-3">
              <div class="info-title">{{ $t("documents") }}</div>
              <hr :style="{ color: style['--control-text-color'] }" />
            </div>
            <div
              v-for="(document, index) in documents"
              :key="index"
              class="col-md-12 document-info mb-2"
            >
              <div class="row">
                <div class="col-5">
                  <div class="file-content d-flex">
                    <i class="bi bi-file-earmark" />
                    <div class="information">
                      <span class="document-title">
                        {{ document.documentName }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="file-content">
                    <div class="information">
                      <span class="document-name"
                        >{{ document.file.name }}
                      </span>
                      <br />
                      <span class="document-size">
                        {{ humanFileSize(document.file.size) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-4" style="text-align: center">
        <button
          class="btn"
          :style="{
            backgroundColor: style['--button-background-color-active'],
            color: style['--button-text-color-active'],
            borderColor: style['--button-border-color-active'],
          }"
          @click="$router.goHome()"
        >
          <i class="bi bi-house" /> {{ $t("home") }}
        </button>
      </div>
    </div>
    <StampsConfirmationModal
      :appointment="appointment"
      :modal-active="showStampConfirmation"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import AppointmentStepHeader from "@/components/AppointmentStepHeader.vue";
import { humanFileSize } from "@/core/helpers/file";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import CompanyId from "@/constants/CompanyId";
import StampsConfirmationModal from "@/views/createAppointment/components/StampsConfirmationModal.vue";

export default defineComponent({
  components: {
    AppointmentStepHeader,
    StampsConfirmationModal,
  },
  props: {
    profile: {
      type: Object,
      value: null,
    },
    appointment: {
      type: Object,
      value: () => ({}),
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
    configuration: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
    plusClient: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props) {
    const showStampConfirmation = ref(false);

    onMounted(() => {
      showStampConfirmation.value = props.appointment.stampPaymentUrl != null;
    });

    const showSpecialFields = ref(
      props.configuration.personalInfo.showSpecialFieldsForm
    );

    const specialFields = ref(props.configuration.personalInfo.specialFields);
    const serviceSpecialFields = ref(
      props.configuration.personalInfo.serviceSpecialFields
    );

    const showTelephone = computed(
      () => props.profile.companyId !== CompanyId.AFPCrecer
    );

    const cellphone = computed(() => {
      if (!props.appointment?.clientPhone) {
        return "";
      }
      const value = props.appointment.clientPhone.match(
        /(\d{3})(\d{3})(\d{4})/
      );
      return "(" + value[1] + ") " + value[2] + "-" + value[3];
    });
    const provider = computed(() => {
      const value = props.configuration.personalInfo.providers.find(
        (x) => x.id === props.appointment.clientPhoneCarrierId
      );
      return value?.name || "";
    });

    const specialField3 = computed(() => {
      const value = props.configuration.personalInfo.miscs.find(
        (x) => x.id === props.answer.personalInfo.specialField3
      );
      return value?.name || "";
    });
    const specialField7 = computed(() => {
      const value = props.answer.personalInfo.specialField7;
      if (!value) return "";
      return format(value, "PPP", { locale: es });
    });
    const documents = computed(() => {
      return props.answer.documents
        ? props.answer.documents.filter((x) => Boolean(x.file))
        : [];
    });

    const style = computed(() => {
      return {
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#272727",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#343434",
        "--control-icon-color":
          props.designConfiguration?.formControlIconColor || "#ffa21a",
        "--control-secondary-text-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "#343434",
        "--control-background-color":
          props.designConfiguration?.fieldsetHeaderBackgroundColor || "#f7f7f7",
        "--document-upload-text-color":
          props.designConfiguration?.buttonTableFontColor || "#f4a63d",
        "--document-upload-background-color":
          props.designConfiguration?.buttonTableBackgroundColor || "#fff",
        "--control-background-color-active":
          props.designConfiguration?.fieldsetHeaderModalFontColor || "#fdfaf6",
        "--control-border-color-active":
          props.designConfiguration?.formControlFontColor || "#f2a641",
        "--button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    return {
      cellphone,
      provider,
      showSpecialFields,
      specialFields,
      serviceSpecialFields,
      specialField3,
      specialField7,
      documents,
      humanFileSize,
      style,
      showStampConfirmation,
      showTelephone
    };
  },
});
</script>
<style lang="scss" scoped>
.title {
  font-size: 36px;
  color: var(--title-color);
  font-weight: bold;
}
.subtitle {
  font-size: 22px;
  color: var(--title-color);
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

.appointment-number {
  color: var(--control-icon-color);
  font-size: 36px;
  font-weight: bold;
  margin-top: -10px;
  padding-left: 10px;
}

.header-icon {
  font-size: 60px;
  color: var(--control-icon-color);
}

.info {
  .container {
    background: var(--control-background-color-active);
    border: 1px solid var(--control-border-color-active);
    border-radius: 4px;
  }

  h6 {
    color: var(--control-text-color);
  }

  .info-title {
    font-size: 16px;
    color: var(--control-text-color);
    font-weight: bold;
  }
  .info-subtitle {
    font-size: 14px;
    color: var(--control-secondary-text-color);
  }
}

.document-info {
  border: 1px solid var(--document-upload-text-color);
  width: 100%;
  background-color: var(--document-upload-background-color);
  border-radius: 4px;
  padding: 0 15px;

  .file-content {
    i {
      font-size: 40px;
      color: var(--document-upload-text-color);
      margin-right: 15px;
    }
    .information {
      margin-top: 12px;
      .document-title {
        font-size: 14px;
        color: var(--document-upload-text-color);
        font-weight: 600;
      }
    }
  }
  .document-name {
    color: var(--document-upload-text-color);
    font-size: 14px;
  }
  .document-size {
    font-size: 12px;
  }
}

@media screen and (max-width: 530px) {
  .header-icon {
    font-size: 40px;
  }

  .title {
    font-size: 28px;
  }
  .subtitle {
    font-size: 16px;
    display: block;
  }

  .appointment-number {
    font-size: 28px;
    padding-left: 0px;
  }
}
</style>
