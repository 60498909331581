<template>
  <div class="container" :class="{ yellow: currentStep > 5 }" :style="style">
    <div class="row">
      <div
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("service") }}:</h6>
        <span class="info-subtitle">{{ service.value.name }}</span>
      </div>
      <div v-if="service.subservice" class="col-md-3 py-3">
        <h6 class="mb-1">{{ $t("subservice") }}:</h6>
        <span class="info-subtitle">{{ service.subservice.name }}</span>
      </div>
      <div
        v-if="location"
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("location") }}:</h6>
        <span class="info-subtitle">{{ location.name }}</span>
      </div>
      <div
        v-if="location"
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("physicalAddress") }}:</h6>
        <span class="address-info">{{ location.address }}</span>
      </div>
      <div
        v-if="space"
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("date") }}:</h6>
        <span class="info-subtitle">{{ date }}</span>
      </div>
      <div
        v-if="space"
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("hour") }}:</h6>
        <span class="info-subtitle">{{ hour }}</span>
      </div>
      <div
        v-if="documents && documents.length"
        class="py-3"
        :class="{
          'col-md-3': service.subservice,
          'col-md-4': !service.subservice,
        }"
      >
        <h6 class="mb-1">{{ $t("documents") }}:</h6>
        <span class="info-subtitle">
          {{ uploadedDocuments.length }} /{{ documents.length }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { format } from "date-fns";

export default defineComponent({
  name: "LocationScreen",
  props: {
    service: {
      type: Object,
      value: () => ({}),
    },
    location: {
      type: Object,
      value: () => ({}),
    },
    space: {
      type: Object,
      value: () => ({}),
    },
    documents: {
      type: Array,
      value: () => [],
    },
    currentStep: {
      type: Number,
      value: 0,
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props) {
    const date = computed(() => {
      if (!props.space) return "";
      return format(props.space.timeStart, "P");
    });
    const hour = computed(() => {
      if (!props.space) return "";
      const { timeStart, timeEnd } = props.space;
      return `${format(timeStart, "p")} - ${format(timeEnd, "p")}`;
    });

    const uploadedDocuments = computed(() => {
      if (!props.documents || !props.documents.length) return [];
      return props.documents.filter((x) => x.file);
    });

    const style = computed(() => {
      return {
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#343434",
        "--control-secondary-text-color":
          props.designConfiguration?.fieldsetHeaderBackgroundColor || "#343434",
        "--control-background-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "#f7f7f7",
        "--control-border-color":
          props.designConfiguration?.formControlFontColor || "#343434",
        "--control-background-color-active":
          props.designConfiguration?.fieldsetHeaderModalFontColor || "#fdfaf6",
        "--control-border-color-active":
          props.designConfiguration?.formControlFontColor || "#f2a641",
      };
    });

    return {
      date,
      hour,
      uploadedDocuments,
      style,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  background: var(--control-background-color);
  border: 1px solid var(--control-border-color);
  border-radius: 4px;

  &.yellow {
    background: var(--control-background-color-active);
    border: 1px solid var(--control-border-color-active);
  }
}
h6 {
  color: var(--control-text-color);
}
.info-subtitle {
  font-size: 14px;
  color: var(--control-secondary-text-color);
}
.address-info {
  color: var(--control-secondary-text-color);
  font-size: 12px;
}
</style>
