export default {
  loading: "Loading",
  government: "Government",
  private: "Private",
  selectCompany: "Select company",
  searchCompany: "Search company",
  searchService: "Search service",
  continue: "Continue",
  moreTimeForYou: "More time for you",
  scheduleYourAppointmentHere: "Schedule your appointment here",
  service: "Service",
  location: "Location",
  dateTime: "Date & Time",
  documents: "Documents",
  personalInformation: "Personal Information",
  confirmation: "Confirmation",
  selectA: "Select a",
  selectCategory: "Select Category",
  subservice: "Sub service",
  select: "Select",
  searchLocation: "Search a location",
  selectMunicipality: "Select a municipality",
  seeLocation: "See location",
  goBack: "Go back",
  locations: "Locations",
  distance: "Distance",
  kilometres: "kilometres",
  physicalAddress: "Physical address",
  from: "From",
  to: "To",
  today: "Today",
  cancel: "Cancel",
  search: "Search",
  all: "All",
  noSpacesAvailable: "No spaces available for this date ({0}).",
  noSearchPerformed: "No search performed.",
  toSearchClickTheBoton:
    "To search click on the button with a magnifying glass",
  selectedDateWasModified: "The selected date was modified",
  available: "Available",
  noAvailable: "No available",
  legend: "Legend",
  upload: "Upload",
  "required-documents": "Required documents",
  required: "Required",
  "press-drag-to-upload-documents": "Press or drag to upload documents",
  date: "Date",
  hour: "Hour",
  "no-required": "No required",
  "only-pdf-files-are-allowed": "Only PDF, JPEG, JPG and PNG files are allowed",
  complete: "Complete",
  "personal-information": "Personal information",
  "additional-information": "Additional Information",
  name: "Name",
  "last-name": "Last name",
  "second-last-name": "Second last name",
  email: "Email",
  "confirm-email": "Confirm email",
  cellphone: "Cellphone",
  provider: "Provider",
  "have-read-and-accept": "I have read and accept the",
  "terms-conditions": "terms and conditions",
  "special-field": "Special field",
  "field-required": "This field is required",
  "please-verify-contact-information": "Please verify contact information",
  "please-verify-additional-information":
    "Please verify additional information",
  "sure-want-to-confirm-appointment":
    "Are you sure you want to confirm this appointment?",
  no: "No",
  yes: "Yes",
  "confirm-appointment-contact":
    "Confirm appointment and verify contact information",
  "information-selected-appointment":
    "The information of the selected appointment is the following",
  comment: "Comment",
  attendees: "Attendees",
  "confirmed-appointment": "Confirmed appoinment",
  "appointment-confirmation-number": "The appointment confirmation number is",
  home: "Home",
  "more-time-for-you": "More time for you",
  "schedule-your-appointment-here": "Schedule your appointment here",
  "cancel-appointment": "Cancel appointment",
  "select-category-services":
    "You must select a category to be able to see the services",
  "appointment-number": "Appointment number",
  number: "Number",
  status: "Status",
  "reason-cancellation": "Reason for Cancellation",
  "appointment-cancelled": "Appointment cancelled",
  "appointment-successfully-cancelled":
    "Your appointment has been successfully cancelled.",
  "you-must-upload-all-required-documents":
    "You must upload all required documents",
  "you-must-cancel-your-appointment-through":
    "You must cancel your appointment through",
  "in-order-to-request-new-appointment":
    "in order to request a new appointment.",
  "appointment-cancellation": "Appointment Cancellation",
  "canceled-appointment-please-confirm":
    "If you already canceled your previous appointment, please confirm your new appointment",
  faq: {
    "how-to-navigate": "How to navigate?",
    "how-to-navigate-response":
      "Navigating is very easy, you will be able to see the services for which appointments are offered. Once the service is selected, it will present you with additional services within that category, by selecting them you will be able to see the required documents and related information. At the bottom you can click on the calendar to coordinate an appointment.",
    "how-to-request-the-appointment": "How to request the appointment?",
    "how-to-request-the-appointment-response":
      "You select the service that interests you, then you can see one or more related sub-services and proceed to select the one that interests you, after reading the information about the requirements you can click on the calendar to coordinate the appointment.",
    "can-request-office": "Can I request the office I want?",
    "can-request-office-response":
      "Yes, you can select the office of your choice, as long as it offers the appointment services you want.",
    "appointment-the-hour-by-order-of-arrival":
      "Is the appointment by the hour or by order of arrival?",
    "appointment-the-hour-by-order-of-arrival-response":
      "Every effort will be made to serve you as close as possible to your appointment time. The order of arrival does not apply.",
    "can-cancel-appointment": "Can I cancel an appointment?",
    "can-cancel-appointment-response":
      "Yes, to cancel your appointment you must click on the CANCEL APPOINTMENT link at the top of the page. You will enter your user account and the appointment number to proceed to confirm the cancellation.",
  },
  fileSizeLimitMessage: 'File size should not exceed 10 MB.',
  decline: "Decline",
  mediaConsentHeader: "Consent for photo, audio and video",
  mediaConsent:
    "I certify that I expressly accept the terms of use regarding photos, videos, and audio as detailed in the preceding form, which I confirm to have read and understood.",
  approvalCode: "Approval Exam Code",
  stamps: 'Stamps',
  office: 'Office',
  client: 'Client',
  stampsWarning: 'If you do not buy the stamps your appointment will be canceled',
  stampsValidationMessage: 'To confirm your appointment and be able to access the service you must have the necessary stamps or purchase them here. You can purchase the stamps for this transaction or enter the stamp number if you have them handy. These stamps are processed by Colecturía Digital. If you decide to buy the stamps here you will only be able to get all of the stamps, not partial stamps. If you wish to proceed to enter the stamp number or purchase them, press the BUY/VALIDATE STAMPS button.',
  buyStamps: 'BUY/VALIDATE',
  yourAppointmentNumberIs: 'The number of your Appointment to confirm is',
  avail: "Avail",
  noAvail: "No avail",
};
