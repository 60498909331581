import { apiTurnos } from "@/core/api/";

export const getByServiceId = (companyId, serviceId, rmo = null) =>
  apiTurnos.get("api/location/byservice/list", {
    params: { companyId, serviceId, rmo },
  });

export const getBySubServiceId = (companyId, serviceId, subServiceId) =>
  apiTurnos.get("api/location/bysubservice/list", {
    params: { companyId, serviceId, subServiceId },
  });

export const getSpecialFields = (locationConfigurationId) =>
  apiTurnos.get(`api/location/${locationConfigurationId}/specialfields`);

export const getMiscData = (locationConfigurationId) =>
  apiTurnos.get(`api/location/${locationConfigurationId}/miscData`);
