<template>
  <div class="container mt-3 mb-5" :style="style">
    <big-loader v-if="loading" class="mt-5" />
    <div v-else class="row">
      <div class="title col-lg-12 mb-2">
        {{ $t("upload") }}
        <span class="marked">{{ $t("required-documents") }}</span>
      </div>
      <div class="col-12 py-0 mb-3">
        <small style="color: red">{{ $t("only-pdf-files-are-allowed") }}</small>
        <br />
        <small style="color: red">{{ $t("fileSizeLimitMessage") }}</small>
      </div>
      <div v-for="(item, index) in documents" :key="index" class="col-md-12">
        <div class="document-title">
          {{ item.documentName }}
          <span v-if="item.isRequired" class="required">
            *{{ $t("required") }}
          </span>
        </div>
        <UploadDocument
          v-model="item.file"
          :button-background-color="
            designConfiguration?.buttonTableBackgroundColor
          "
          :button-text-color="designConfiguration?.buttonTableFontColor"
        />
        <hr />
      </div>
      <div v-if="missingDocuments && submitted" class="col-12">
        <div class="alert alert-warning" role="alert">
          {{ $t("you-must-upload-all-required-documents") }}
        </div>
      </div>
      <div class="col-12 mt-5 d-flex justify-content-between">
        <button
          class="btn"
          :style="{
            backgroundColor: style['--return-button-background-color'],
            color: style['--return-button-text-color'],
            borderColor: style['--return-button-border-color'],
          }"
          @click="$emit('back')"
        >
          <i class="bi bi-arrow-left-circle" /> {{ $t("goBack") }}
        </button>

        <button
          class="btn"
          :style="{
            backgroundColor: style['--return-button-background-color-active'],
            color: style['--return-button-text-color-active'],
            borderColor: style['--return-button-border-color-active'],
          }"
          @click="onContinue()"
        >
          {{ $t("continue") }} <i class="bi bi-arrow-right-circle" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import BigLoader from "@/components/BigLoader.vue";
import UploadDocument from "@/components/UploadDocument.vue";
import { getDocuments as _getDocuments } from "@/services/AppointmentService";

export default defineComponent({
  components: {
    BigLoader,
    UploadDocument,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const documents = ref([]);
    const submitted = ref(false);
    onMounted(() => {
      if (props.answer && props.answer.length) {
        documents.value = props.answer;
        return;
      }
      loading.value = true;
      _getDocuments(props.service.value.id)
        .then(({ data }) => {
          documents.value = data.map((x) => ({ ...x, file: null }));
        })
        .finally(() => (loading.value = false));
    });

    const missingDocuments = computed(() => {
      return documents.value.some((x) => x.isRequired && !x.file);
    });

    const onContinue = () => {
      if (missingDocuments.value) return (submitted.value = true);
      emit("update:answer", [...documents.value]);
      emit("next");
    };

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#272727",
        "--return-button-text-color":
          props.designConfiguration?.buttonFontColor || "#343434",
        "--return-button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "transparent",
        "--return-button-border-color":
          props.designConfiguration?.buttonBorderColor || "#343434",
        "--return-button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--return-button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--return-button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    return {
      loading,
      documents,
      missingDocuments,
      onContinue,
      style,
      submitted,
    };
  },
});
</script>
<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: normal;
  .marked {
    font-weight: bold;
  }
}
.document-title {
  color: var(--control-text-color);
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
  .required {
    color: #ff3d57;
  }
}
</style>
