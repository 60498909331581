import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Loading from "vue-loading-overlay";
import i18n from "./plugins/i18n";

import VueGtag from 'vue-gtag';

import "@vuepic/vue-datepicker/dist/main.css";

import "./assets/main.css";

const app = createApp(App);

// eslint-disable-next-line vue/multi-word-component-names
app.component("loading", Loading);

// Configuration VueAnalytics and routing
app.use(VueGtag,
  {
    config: {
      id: 'G-3L4REEJ1MM',
    },
  }).use(router);

app.use(i18n);

app.mount("#app");

// Application Insights
import { APPINSIGHTS } from '@/config';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights = {};

if(APPINSIGHTS.KEY !== undefined)
{
  appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: APPINSIGHTS.KEY
    }
  });
  appInsights.loadAppInsights();
}

export default appInsights;