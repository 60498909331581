<template>
  <div
    class="container mt-4"
    :class="{ 'pt-5': loadings.configuration }"
    :style="style"
  >
    <big-loader v-if="loadings.configuration" class="mt-5" />
    <div v-else class="row">
      <div class="title col-lg-12 mb-4">
        {{ $t("selectA") }} <span class="marked">{{ $t("service") }}</span>
      </div>
      <div
        v-if="hasCategories"
        class="col-lg-4 col-md-6"
        :class="{ 'col-lg-12': !hasServices }"
      >
        <base-select
          v-model="category"
          :color="style['--control-text-color']"
          :color-icon="designConfiguration?.formControlIconColor || '#ffa21a'"
          custom-class="size-lg"
          :options="categories"
          :placeholder="$t('selectCategory')"
          prepend-icon="bi-grid-3x3-gap-fill"
        />
      </div>

      <div v-if="hasServices" class="col">
        <base-input
          v-model="search"
          :clearable="true"
          :color="style['--control-text-color']"
          :color-icon="designConfiguration?.formControlIconColor || '#ffa21a'"
          custom-class="size-lg"
          :placeholder="$t('searchService')"
          prepend-icon="bi-search"
          type="text"
        />
      </div>
      <div
        v-if="hasCategories && !hasServices && !loadings.services"
        class="col-lg-12 mt-5"
      >
        <h3
          class="text-center mt-3"
          :style="{ color: style['--control-text-color'] }"
        >
          <i
            class="bi bi-info-circle"
            :style="{
              color: designConfiguration?.formControlIconColor || '#ffa21a',
            }"
          />&nbsp;
          {{ $t("select-category-services") }}
        </h3>
      </div>
      <div v-if="loadings.services" class="col-lg-12 mt-5">
        <big-loader class="mt-5" />
      </div>
      <div v-if="!loadings.services && hasServices" class="col-md-12 mt-5">
        <div v-for="(service, i) in services" :key="i" class="accordion">
          <div class="accordion-item">
            <h2
              :id="`service-${i}`"
              class="accordion-header"
              :class="{ simple: !service.subServices }"
              @click="onSelect(service)"
            >
              <button
                class="accordion-button collapsed fw-bold service-name"
                type="button"
              >
                {{ service.name }}
              </button>
              <i
                class="bi cursor-pointer"
                :class="{
                  'bi-arrow-down-circle': configuration.hasSubServices,
                  'bi-arrow-right-circle': !configuration.hasSubServices,
                }"
              />
            </h2>
            <div
              class="accordion-collapse collapse"
              :class="{
                show:
                  selectedService &&
                  selectedService.id == service.id &&
                  configuration.hasSubServices,
              }"
            >
              <div class="accordion-body text-justify">
                <big-loader v-if="loadings.subServices" class="my-5" />
                <div v-else class="row">
                  <div
                    v-for="(item, index) in service.subServices"
                    :key="index"
                    class="col-lg-4 col-md-6 mb-3"
                  >
                    <div
                      class="card cursor-pointer"
                      @click="onSelectSubService(item)"
                    >
                      <div
                        class="card-body text-capitalize d-flex align-items-center"
                      >
                        <span class="dot me-2" />
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BigLoader from "@/components/BigLoader.vue";
import { USER_SESSION, MINIMUM_STEP } from "@/constants/SessionStorage";
import {
  getServiceConfiguration as _getServiceConfiguration,
  getServicesByCategoryId as _getServicesByCategoryId,
  getSubServices as _getSubServices,
} from "@/services/AppointmentService";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { removeAccents } from "@/core/helpers/text";

export default {
  name: "ServiceScreen",
  components: {
    BaseInput,
    BaseSelect,
    BigLoader,
  },
  props: {
    profile: {
      type: Object,
      value: null,
    },
    configuration: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props, { emit }) {
    // Clean the minimum step
    sessionStorage.removeItem(MINIMUM_STEP);

    const loadings = reactive({
      configuration: false,
      services: false,
      subServices: false,
    });

    const userSession = sessionStorage.getItem(USER_SESSION);
    const metadata = JSON.parse(userSession).metadata;

    const search = ref(props.answer?.search || "");
    const category = ref(props.answer?.categoryId || null);
    const selectedService = ref(props.answer?.value || null);
    const hasCategories = computed(() => {
      const { configuration } = props;
      return (
        configuration &&
        configuration.hasCategories &&
        configuration.categories &&
        !!configuration.categories.length
      );
    });
    const categories = computed(() => {
      const { configuration } = props;
      if (
        !configuration ||
        !configuration.categories ||
        !configuration.categories.length
      )
        return [];
      return configuration.categories;
    });

    const hasServices = computed(() => {
      const { configuration } = props;
      return (
        configuration && configuration.services && configuration.services.length
      );
    });

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#272727",
        "--button-text-color":
          props.designConfiguration?.buttonFontColor || "#272727",
        "--button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "white",
        "--button-border-color":
          props.designConfiguration?.buttonBorderColor || "#272727",
        "--button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    const services = computed(() => {
      const { configuration } = props;
      if (
        !configuration ||
        !configuration.services ||
        !configuration.services.length
      )
        return [];
      if (!search.value) return configuration.services;
      const term = removeAccents(search.value);
      return configuration.services.filter((x) => {
        return removeAccents(x.name).indexOf(term) !== -1;
      });
    });

    onMounted(async () => {
      if (props.configuration) return;

      loadings.configuration = true;
      await _getServiceConfiguration(props.profile.companyId).then(
        ({ data }) => {
          emit("update:configuration", data);
        }
      );

      await handleAccess().finally(() => (loadings.configuration = false));
    });

    watch(
      () => category.value,
      (value) => {
        if (!value) return;
        loadings.services = true;
        search.value = "";
        getServices(props.profile.companyId, value);
      }
    );

    const handleAccess = async () => {
      if (metadata.serviceId) {
        if (services.value && services.value.length) {
          const profileService = services.value.find(
            (e) => e.id == metadata.serviceId
          );

          if (profileService) {
            selectedService.value = profileService;

            emit("update:answer", {
              categoryId: 0,
              search: search.value,
              value: profileService,
            });

            await _getSubServices(props.profile.companyId, profileService.id)
              .then(({ data }) => {
                if (!data.length) {
                  sessionStorage.setItem(MINIMUM_STEP, "2");
                  emit("next");
                } else {
                  services.value.find(
                    (e) => e.id == metadata.serviceId
                  ).subServices = data || [];
                  selectedService.value.subServices = data || [];

                  const profileSubService = data.find(
                    (e) => e.id == metadata.subServiceId
                  );

                  if (profileSubService) {
                    emit("update:answer", {
                      categoryId: 0,
                      search: search.value,
                      value: profileService,
                      subservice: profileSubService,
                    });

                    sessionStorage.setItem(MINIMUM_STEP, "2");
                    emit("next");
                  }
                }
              })
              .finally(() => (loadings.subServices = false));
          }
        }
      }
    };

    const getServices = async (companyId, categoryId) => {
      _getServicesByCategoryId(companyId, categoryId)
        .then(({ data }) => {
          emit("update:configuration", {
            ...props.configuration,
            services: data,
          });
        })
        .finally(() => (loadings.services = false));
    };

    const getSubServices = (serviceId) => {
      loadings.subServices = true;
      _getSubServices(props.profile.companyId, serviceId)
        .then(({ data }) => {
          selectedService.value.subServices = data || [];
        })
        .finally(() => (loadings.subServices = false));
    };

    const onSelect = (value) => {
      if (props.configuration.hasSubServices) {
        selectedService.value = selectedService.value !== value ? value : null;
        if (!value.subServices) {
          setTimeout(() => {
            getSubServices(value.id);
          }, 400);
        }
        return;
      }
      selectedService.value = value;
      emit("update:answer", {
        categoryId: category.value,
        search: search.value,
        value: value,
      });
      emit("next");
    };

    const onSelectSubService = (value) => {
      emit("update:answer", {
        categoryId: category.value,
        search: search.value,
        value: selectedService.value,
        subservice: value,
      });
      emit("next");
    };

    return {
      search,
      category,
      loadings,
      hasCategories,
      hasServices,
      categories,
      services,
      onSelect,
      selectedService,
      onSelectSubService,
      style,
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: normal;
  .marked {
    font-weight: bold;
  }
}
.accordion-button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color);
  border: 2px solid var(--button-border-color);
}
.accordion-button:after {
  content: none;
}
.accordion-header {
  position: relative;
  i {
    position: absolute;
    right: 15px;
    color: var(--button-text-color);
    font-size: 24px;
    top: 10px;
    z-index: 3;
  }
  &.simple:hover {
    button {
      background-color: var(--button-background-color-active) !important;
      color: var(--button-text-color-active);
      border: 2px solid var(--button-border-color-active);
    }
    i {
      color: var(--button-text-color-active);
    }
  }
}

.accordion-body {
  min-height: 200px;

  .card {
    font-size: 16px;
    .dot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--button-background-color);
    }
    &:hover {
      background-color: var(--button-background-color-active);
      color: var(--button-text-color-active);
      .dot {
        background-color: var(--button-background-color-active);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .accordion-button {
    font-size: 14px;
    padding-right: 50px;
  }
}
</style>
