import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import CreateAppointment from "../views/createAppointment/CreateAppointment.vue";
import CancelAppointment from "../views/CancelAppointment.vue";
import {
  USER_SESSION,
  PLUS_CLIENT,
  DESING_CONFIGURATION,
  QSINFO,
} from "@/constants/SessionStorage";

import { APPINSIGHTS } from "@/config";
import appInsights from "@/main";

import { login as _login } from "@/services/AccountService";
import { getById as _getPlusClientById } from "@/services/PlusClientService";
import { getCompany as _getCompany } from "@/services/PublicService";

import eventBus from "@/bus/eventBus";

// eslint-disable-next-line no-undef
const _env = process.env;

const initCompanyStyles = async (onLoginSuccessful) => {
  const pageAccessedByReload =
    (window.performance.navigation &&
      window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType("navigation")
      .map((nav) => nav.type)
      .includes("reload");

  // if the company id was passed by querystring we need to login and redirect
  const urlSearchParams = new URLSearchParams(window.location.search);
  let qValues = Object.fromEntries(urlSearchParams.entries());

  if (pageAccessedByReload && !qValues.companyid) {
    const sessionQValues = sessionStorage.getItem(QSINFO);

    if (sessionQValues != null) {
      qValues = JSON.parse(sessionQValues);
    }
  }

  if (qValues.companyid) {
    sessionStorage.setItem(QSINFO, JSON.stringify(qValues));

    await _getCompany(qValues.companyid).then(async ({ data: company }) => {
      if (company && company.id) {
        let qsInfo = null;

        const loginResult = await _login(qValues)
          .then(async ({ data: userInfo }) => {
            if (!userInfo) return false;

            sessionStorage.setItem(USER_SESSION, JSON.stringify(userInfo));

            let desingConfiguration = null;

            if (company.plusClientId) {
              await _getPlusClientById(company.plusClientId).then(
                ({ data: plusClient }) => {
                  sessionStorage.setItem(
                    PLUS_CLIENT,
                    JSON.stringify(plusClient)
                  );

                  desingConfiguration =
                    plusClient.plusClientLayoutDesignConfigurations[0];
                }
              );
            }

            sessionStorage.setItem(
              DESING_CONFIGURATION,
              JSON.stringify(desingConfiguration)
            );

            // check if there is any qstring value
            let qsInfo = sessionStorage.getItem(QSINFO);

            if (qsInfo != null) qsInfo = JSON.parse(qsInfo);

            return true;
          })
          .catch(() => {
            // No appointment plus configured for this client
          });

        if (loginResult && onLoginSuccessful) onLoginSuccessful(qsInfo);
      }
    });
  }
};

const router = createRouter({
  history: createWebHistory(_env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      beforeEnter: async (to, from, next) => {
        let nextPayload = null;

        let onLoginSuccessFul = (qsInfo) => {
          nextPayload = { name: "createAppointment", query: qsInfo };
        };

        await initCompanyStyles(onLoginSuccessFul);

        if (nextPayload) next(nextPayload);
        else next();

        // Emit an event to notify that the styles have loaded
        eventBus.emit("loadedstyles", true);
      },
    },
    {
      path: "/create",
      name: "createAppointment",
      component: CreateAppointment,
      beforeEnter: async (to, from, next) => {
        await initCompanyStyles();
        if (sessionStorage.getItem(USER_SESSION)) next();
        else next({ name: "home" });

        // Emit an event to notify that the styles have loaded
        eventBus.emit("loadedstyles", true);
      },
    },
    {
      path: "/CancelAppointment",
      name: "CancelAppointment",
      component: CancelAppointment,
      beforeEnter: async (to, from, next) => {
        await initCompanyStyles();
        
        next();

        // Emit an event to notify that the styles have loaded
        eventBus.emit("loadedstyles", true);
      },
    },
    { path: "/:pathMatch(.*)*", component: HomeView },
  ],
});

router.goHome = () => {
  const userSession = sessionStorage.getItem(USER_SESSION);
  const user = JSON.parse(userSession);

  if (user?.metadata.callback) {
    window.location = user.metadata.callback;
    return;
  }

  router.push({ name: "home" });
};

if (APPINSIGHTS.KEY !== undefined) {
  router.afterEach((to) => {
    appInsights.trackPageView({ name: to.name });
  });
}

export default router;
