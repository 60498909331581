<template>
  <div class="container my-4" :style="style">
    <div class="row">
      <div class="col-lg-8 mt-3 mt-lg-0">
        <div class="row">
          <div class="col-12 title">
            {{ $t("select") }} <span class="marked">{{ $t("dateTime") }}</span>
          </div>
          <div class="col-12 mt-1 mb-4">
            <div class="row">
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-sm-5">
                    <BaseDatePicker
                      v-model="from"
                      :clearable="false"
                      :color="style['--control-text-color']"
                      :label="$t('from')"
                      :min-date="minFromDate"
                    />
                  </div>
                  <div class="col-sm-5">
                    <BaseDatePicker
                      v-model="to"
                      :clearable="false"
                      :color="style['--control-text-color']"
                      :label="$t('to')"
                      :max-date="maxDateToCoordinate"
                      :min-date="from"
                    />
                  </div>
                  <div class="col-sm-2 search pt-2 d-none d-sm-block">
                    <button class="btn mt-4 search-button" @click="onSearch()">
                      <i class="bi bi-search" />
                    </button>
                  </div>
                  <div class="col-12 search d-grid d-sm-none mb-3">
                    <button class="btn btn-dark mt-4" @click="onSearch()">
                      {{ $t("search") }}<i class="bi bi-search ms-2" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="searched && !loading"
                class="col-xl-5 mt-3 mt-xl-0 selected-date d-flex justify-content-around align-items-end"
              >
                <button
                  class="btn btn-outline-primary option-step"
                  :disabled="isSameDay(currentDate, from)"
                  @click="removeDay()"
                >
                  <i class="bi bi-chevron-left" />
                </button>
                <span
                  class="current-date mb-2"
                  :style="{ color: style['--title-color'] }"
                >
                  {{ selectedDate }}
                </span>
                <button
                  class="btn btn-outline-primary option-step"
                  :disabled="isSameDay(currentDate, to)"
                  @click="addDay()"
                >
                  <i class="bi bi-chevron-right" />
                </button>
              </div>
            </div>
            <div
              v-if="searched && !loading"
              class="col-12 mt-3 d-block d-lg-none date-picker"
            >
              <Datepicker
                v-model="currentDate"
                auto-apply
                :enable-time-picker="false"
                :format-locale="$i18n.locale == 'es' ? es : enUS"
                inline
                :locale="$i18n.locale == 'es' ? 'es' : 'en'"
                :max-date="to"
                :min-date="minFromDate"
              >
                <template #day="{ day, date }">
                  <template v-if="dateHaveSpace(date)">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <div class="dot small" />
                      <span>
                        {{ day }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    {{ day }}
                  </template>
                </template>
              </Datepicker>
            </div>
            <div
              v-if="searched && !loading"
              class="col-12 d-flex mt-1 d-block d-lg-none"
            >
              <div class="dot mt-1 me-1" />
              {{ $t("available") }}
            </div>
          </div>
          <div v-if="loading" class="col-12">
            <BigLoader />
          </div>
          <div v-else-if="!searched || filterChange" class="col-12">
            <div class="warning-message">
              <i class="bi bi-bell" />
              <div>
                {{
                  $t(
                    filterChange
                      ? "selectedDateWasModified"
                      : "noSearchPerformed"
                  )
                }}
                <br />
                <small>{{ $t("toSearchClickTheBoton") }}</small>
              </div>
            </div>
          </div>
          <div
            v-else-if="searched"
            class="col-12"
            :style="{ 'pointer-events': selectedSpace ? 'none' : 'all' }"
          >
            <div class="row">
              <div class="col-12">
                <hr />
              </div>
              <div class="col-md-5">
                <div class="form-check form-check-inline me-sm-2">
                  <input
                    id="AMRadio"
                    v-model="hoursIndicator"
                    class="form-check-input cursor-pointer"
                    name="hoursIndicator"
                    type="radio"
                    value="AM"
                  />
                  <label class="form-check-label cursor-pointer" for="AMRadio">
                    AM
                  </label>
                </div>
                <div class="form-check form-check-inline mx-sm-2">
                  <input
                    id="PMRadio"
                    v-model="hoursIndicator"
                    class="form-check-input cursor-pointer"
                    name="hoursIndicator"
                    type="radio"
                    value="PM"
                  />
                  <label class="form-check-label cursor-pointer" for="PMRadio">
                    PM
                  </label>
                </div>
                <div
                  v-if="windowWidth > 630"
                  class="form-check form-check-inline ms-sm-2"
                >
                  <input
                    id="ALLRadio"
                    v-model="hoursIndicator"
                    class="form-check-input cursor-pointer"
                    name="hoursIndicator"
                    type="radio"
                    value="ALL"
                  />
                  <label class="form-check-label cursor-pointer" for="ALLRadio">
                    {{ $t("all") }}
                  </label>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <strong
                  class="me-2 d-block d-lg-none d-xl-block"
                  :style="{ color: style['--title-color'] }"
                >
                  {{ $t("legend") }}:
                </strong>
                <div class="legend d-flex">
                  <div
                    class="hour d-flex justify-content-center align-items-center"
                  >
                    .
                  </div>
                  <small
                    class="ms-2"
                    :style="{ color: style['--control-text-color'] }"
                    >{{ $t(windowWidth < 630 ? "avail" : "available") }}</small
                  >
                </div>
                <div class="legend d-flex mx-3">
                  <div
                    class="hour disabled d-flex justify-content-center align-items-center"
                  >
                    .
                  </div>
                  <small
                    class="ms-2"
                    :style="{ color: style['--control-text-color'] }"
                    >{{
                      $t(windowWidth < 630 ? "noAvail" : "noAvailable")
                    }}</small
                  >
                </div>
              </div>
              <div class="col-12 d-flex flex-wrap">
                <div
                  v-for="option in AMOptions"
                  :key="option"
                  class="hour me-3 d-flex justify-content-center align-items-center cursor-pointer mt-2 mt-sm-3"
                  :class="{
                    active: hourSelected(option),
                    disabled: !isAvailableHour(option),
                  }"
                  @click="onSelectHour(option)"
                >
                  {{ option }}
                </div>
                <div
                  v-for="option in PMOptions"
                  :key="option"
                  class="hour me-3 d-flex justify-content-center align-items-center cursor-pointer mt-2 mt-sm-3"
                  :class="{
                    active: hourSelected(option + 12),
                    disabled: !isAvailableHour(option + 12),
                  }"
                  @click="onSelectHour(option + 12)"
                >
                  {{ option }}
                </div>
              </div>
              <div class="col-12 mt-3 spaces-content">
                <div class="row">
                  <div
                    v-for="space in spaces"
                    :key="space.clientAppointmentId"
                    class="col-sm-6 col-md-4 col-xl-3 mb-2"
                  >
                    <div
                      class="space-block px-1 py-3 cursor-pointer"
                      :class="{
                        active: space.clientAppointmentId === selectedSpace,
                      }"
                      @click="onSelect(space)"
                    >
                      {{ spaceHours(space) }}
                    </div>
                  </div>
                  <div v-if="!spaces.length" class="col-md-12">
                    <div class="warning-message">
                      <i class="bi bi-bell" />
                      <div>
                        {{ plusClient.useCustomSearchMessage ? $i18n.locale == 'es' ? plusClient.notFoundMessage_ES : plusClient.notFoundMessage_EN : $t("noSpacesAvailable", [selectedDate]) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <button
              class="btn"
              :style="{
                backgroundColor: style['--return-button-background-color'],
                color: style['--return-button-text-color'],
                borderColor: style['--return-button-border-color'],
              }"
              @click="$emit('back')"
            >
              <i class="bi bi-arrow-left-circle" /> {{ $t("goBack") }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="searched && !loading" class="col-lg-4 pt-4 d-none d-lg-block">
        <div class="row">
          <div class="col-12 mt-3 date-picker">
            <Datepicker
              v-model="currentDate"
              auto-apply
              :enable-time-picker="false"
              :format-locale="$i18n.locale == 'es' ? es : enUS"
              inline
              :locale="$i18n.locale == 'es' ? 'es' : 'en'"
              :max-date="to"
              :min-date="minFromDate"
            >
              <template #day="{ day, date }">
                <template v-if="dateHaveSpace(date)">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                    :style="{ color: style['--calendar-text-color'] }"
                  >
                    <div class="dot small" />
                    <span>
                      {{ day }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <span>
                    {{ day }}
                  </span>
                </template>
              </template>
            </Datepicker>
          </div>
          <div
            class="col-12 d-flex mt-1"
            :style="{ color: style['--control-text-color'] }"
          >
            <div class="dot mt-1 me-1" />
            {{ $t("available") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from "vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import Datepicker from "@vuepic/vue-datepicker";
import {
  getAvailableSpaces as _getAvailableSpaces,
  prereserve as _prereserve,
} from "@/services/AppointmentService";
import {
  addDays,
  compareAsc,
  compareDesc,
  format,
  isSameDay,
  set,
  setHours,
} from "date-fns";
import { enUS, es } from "date-fns/locale";
import BigLoader from "@/components/BigLoader.vue";
import isSameHour from "date-fns/isSameHour";
import { t } from "@/plugins/i18n";
import { USER_SESSION } from "@/constants/SessionStorage";

export default defineComponent({
  components: {
    BaseDatePicker,
    Datepicker,
    BigLoader,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
    plusClient: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props, { emit }) {
    const actualDay = new Date();
    let minFromDate = actualDay;

    if (!props.location.allowCurrentDayCoordination) {
      const daysToAdd = props.location.appointmentStartDays || 1;

      minFromDate = new Date(
        actualDay.setDate(actualDay.getDate() + daysToAdd)
      );
    }

    const from = ref(minFromDate);
    const to = ref(
      addDays(
        minFromDate,
        props.location.preventCoordinateAppointment && props.location.maxDayCoordinateAppointment < 7
          ? props.location.maxDayCoordinateAppointment
          : 7
      )
    );
    const currentDate = ref(minFromDate);
    const hoursIndicator = ref("ALL");
    const loading = ref(false);
    const searched = ref(false);
    const filterChange = ref(false);
    const selectedHours = ref([]);
    const officeOpenTime = ref(props.location.officeOpenTime || "08:00:00");
    const officeCloseTime = ref(props.location.officeCloseTime || "17:00:00");
    const spacesList = ref([]);
    const selectedSpace = ref(null);

    const windowWidth = ref(window.innerWidth);

    const userSession = sessionStorage.getItem(USER_SESSION);
    const metadata = JSON.parse(userSession).metadata;

    window.addEventListener("resize", () => {
      windowWidth.value = window.innerWidth;
    });

    watch(
      () => windowWidth.value,
      (value) => {
        if (value < 630) hoursIndicator.value = "AM";
        else hoursIndicator.value = "ALL";
      },
      { immediate: true }
    );

    watch(
      () => from.value,
      (value) => {
        currentDate.value = value;
        filterChange.value = true;
        if (compareAsc(value, to.value) === 1) {
          to.value = addDays(value, 7);
        }
      }
    );

    watch(
      () => to.value,
      () => {
        filterChange.value = true;
      }
    );

    watch(
      () => hoursIndicator.value,
      () => (selectedHours.value = [])
    );

    const selectedDate = computed(() =>
      format(currentDate.value, "PPP", { locale: es })
    );

    const AMOptions = computed(() => {
      const [hour] = officeOpenTime.value.split(":");
      const start = parseInt(hour);
      if (hoursIndicator.value == "PM") return [12];
      return Array.from({ length: 13 - start }, (_, i) => i + start);
    });

    const PMOptions = computed(() => {
      const [hour] = officeCloseTime.value.split(":");
      const end = parseInt(hour);
      if (hoursIndicator.value == "AM") return [];
      return Array.from({ length: end - 12 }, (_, i) => i + 1);
    });

    const maxDateToCoordinate = computed(() => {
      if (props.location.preventCoordinateAppointment) {
        const maxDate = addDays(
          new Date(),
          props.location.maxDayCoordinateAppointment
        );

        return maxDate;
      }
      return null;
    });

    watch(
      () => maxDateToCoordinate.value,
      (value) => {
        if (compareAsc(to.value, value) === 1) {
          to.value = value;
        }
      },
      { immediate: true }
    );

    const onSearch = () => {
      const [OpenHour, OpenMinute] = officeOpenTime.value.split(":");
      const [CloseHour, CloseMinute] = officeCloseTime.value.split(":");

      const startDate = set(from.value, {
        hours: OpenHour,
        minutes: OpenMinute,
        seconds: "00",
      });
      const endDate = set(to.value, {
        hours: CloseHour,
        minutes: CloseMinute,
        seconds: "00",
      });

      loading.value = true;
      searched.value = true;
      selectedHours.value = [];
      filterChange.value = false;

      var startDateParam = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      ).toISOString();

      var endDateParam = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      ).toISOString();

      _getAvailableSpaces({
        locationConfigurationId: props.location.locationConfigurationId,
        serviceId: props.service.value.id,
        startDate: startDateParam,
        endDate: endDateParam,
      })
        .then(({ data }) => {
          const filtered =
            metadata.startDate && metadata.endDate
              ? (data || []).filter((e) => {
                  const timeStart = new Date(e.timeStart);
                  const timeEnd = new Date(e.timeEnd);
                  const filterTimeStart = new Date(metadata.startDate);
                  const filterTimeEnd = new Date(metadata.endDate);

                  return (
                    timeStart >= filterTimeStart && timeEnd <= filterTimeEnd
                  );
                })
              : data || [];

          spacesList.value = filtered
            .map((x) => ({
              ...x,
              timeStart: new Date(x.timeStart),
              timeEnd: new Date(x.timeEnd),
            }))
            .sort((a, b) => compareAsc(a.timeStart, b.timeStart));
          if (spacesList.value.length) {
            currentDate.value = spacesList.value[0].timeStart;
          }
        })
        .finally(() => (loading.value = false));
    };

    const spaces = computed(() => {
      let value = spacesList.value.filter((x) =>
        isSameDay(currentDate.value, x.timeStart)
      );
      let hours = selectedHours.value.length
        ? selectedHours.value
        : AMOptions.value.concat(PMOptions.value.map((x) => x + 12));

      value = value.filter((space) => {
        return !!hours.find((hour) =>
          isSameHour(space.timeStart, setHours(currentDate.value, hour))
        );
      });
      return value;
    });

    const dateHaveSpace = (date) => {
      return spacesList.value.filter((x) => isSameDay(date, x.timeStart))
        .length;
    };

    const addDay = () => {
      selectedHours.value = [];
      const nextDayWithSpace = spacesList.value.find(
        (x) =>
          compareAsc(
            x.timeStart,
            set(addDays(currentDate.value, 1), {
              hours: 0,
              minutes: 0,
              seconds: 0,
            })
          ) == 1
      );
      currentDate.value = nextDayWithSpace
        ? nextDayWithSpace.timeStart
        : addDays(currentDate.value, 1);
    };
    const removeDay = () => {
      selectedHours.value = [];
      const previousDayWithSpace = spacesList.value.findLast(
        (x) =>
          compareDesc(
            x.timeStart,
            set(currentDate.value, {
              hours: 0,
              minutes: 0,
              seconds: 0,
            })
          ) == 1
      );
      currentDate.value = previousDayWithSpace
        ? previousDayWithSpace.timeStart
        : addDays(currentDate.value, -1);
    };

    const spaceHours = (space) => {
      if (
        selectedSpace.value &&
        selectedSpace.value === space.clientAppointmentId
      )
        return `${t("loading")}....`;
      return `${format(space.timeStart, "p")} - ${format(space.timeEnd, "p")}`;
    };

    const onSelectHour = (hour) => {
      const hours = selectedHours.value;
      const index = hours.findIndex((x) => x == hour);
      if (index == -1) hours.push(hour);
      else hours.splice(index, 1);
    };

    const hourSelected = (hour) => {
      return selectedHours.value.includes(hour);
    };

    const isAvailableHour = (hour) => {
      return spacesList.value.some((x) =>
        isSameHour(setHours(currentDate.value, hour), x.timeStart)
      );
    };

    const onSelect = (space) => {
      selectedSpace.value = space.clientAppointmentId;
      _prereserve(space.clientAppointmentId)
        .then(() => {
          emit("update:answer", {
            ...space,
          });
          emit("next");
        })
        .finally(() => (selectedSpace.value = null));
    };

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--button-text-color":
          props.designConfiguration?.buttonFontColor || "#ffa21a",
        "--button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "white",
        "--button-border-color":
          props.designConfiguration?.buttonBorderColor || "#ffa21a",
        "--button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
        "--control-icon-color":
          props.designConfiguration?.formControlIconColor || "#ffa21a",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#272727",
        "--control-background-color-active":
          props.designConfiguration?.fieldsetHeaderModalFontColor || "#f7f7f7",
        "--control-checkbox-mark":
          props.designConfiguration?.checkboxMarkColor || "#ffa21a",
        "--option-step-background-color":
          props.designConfiguration?.buttonBackgroundColor || "#F7F7F7",
        "--calendar-background-color":
          props.designConfiguration?.calendarItemBackgroundColor || "white",
        "--calendar-selected-background-color":
          props.designConfiguration?.calendarSelectedDateBackgroundColor ||
          "#ffa21a",
        "--calendar-text-color":
          props.designConfiguration?.calendarItemFontColor || "#343434",
        "--calendar-hover-color":
          props.designConfiguration?.calendarItemBorderColor || "#faebd5",
        "--dot-color":
          props.designConfiguration?.calendarHeaderBackgroundColor || "#16a2c0",
        "--return-button-text-color":
          props.designConfiguration?.buttonFontColor || "#343434",
        "--return-button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "transparent",
        "--return-button-border-color":
          props.designConfiguration?.buttonBorderColor || "#343434",
      };
    });

    return {
      from,
      minFromDate,
      to,
      enUS,
      es,
      format,
      selectedDate,
      hoursIndicator,
      AMOptions,
      PMOptions,
      loading,
      searched,
      onSearch,
      spaces,
      isSameDay,
      addDay,
      removeDay,
      currentDate,
      spaceHours,
      onSelectHour,
      hourSelected,
      isAvailableHour,
      filterChange,
      onSelect,
      selectedSpace,
      dateHaveSpace,
      style,
      maxDateToCoordinate,
      windowWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: normal;
  .marked {
    font-weight: bold;
  }
}
::v-deep {
  .form-label {
    margin-bottom: 0.5rem !important;
  }
}
.date-picker {
  ::v-deep {
    .dp__outer_menu_wrap {
      width: 100%;
    }
  }
}

.search-button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color);
  border-color: var(--button-border-color);
  &:hover {
    background-color: var(--button-background-color-active) !important;
    color: var(--button-text-color-active);
    border-color: var(--button-border-color-active);
  }
}

.form-check-input:checked {
  background-color: var(--control-checkbox-mark) !important;
  border-color: var(--control-checkbox-mark);
}

.form-check-label {
  color: var(--control-text-color);
}

.selected-date {
  .current-date {
    font-weight: bold;
  }
  .option-step {
    background: var(--option-step-background-color);
    border-color: transparent;
    &:hover {
      i {
        color: var(--control-icon-color);
      }
    }
    &:disabled {
      i {
        color: #f7f7f7;
      }
    }
  }
}

.hour {
  border: 1px solid var(--button-border-color);
  border-radius: 4px;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  font-weight: 700;
  height: 50px;
  width: 50px;

  &:hover {
    background-color: var(--button-background-color-active);
    border-color: var(--button-text-color-active);
  }
  &.active {
    background-color: var(--button-background-color-active);
    border-color: var(--button-border-color-active);
    color: var(--button-text-color-active);
  }
  &.disabled {
    background-color: #e5e5e5;
    border-color: #a3a3a3;
    color: #a3a3a3;
    pointer-events: none;
  }
}
.legend {
  .hour {
    height: 25px;
    width: 25px;
    pointer-events: none;
  }
}
.spaces-content {
  max-height: 350px;
  overflow-y: auto;
  .space-block {
    border: 1px solid var(--button-border-color);
    border-radius: 4px;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    font-size: 14px;
    text-align: center;
    &:hover,
    &.active {
      color: var(--button-text-color-active);
      background-color: var(--button-background-color-active);
      border-color: var(--button-border-color-active);
    }
  }
}
.warning-message {
  background-color: var(--control-background-color-active);
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  i {
    color: var(--control-icon-color);
    font-size: 24px;
  }
  div {
    font-weight: 700;
    color: var(--control-text-color);
    margin-top: 10px;
    small {
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.dot {
  width: 15px;
  height: 15px;
  background-color: var(--dot-color);
  border-radius: 100%;
  &.small {
    width: 5px;
    height: 5px;
  }
}
</style>
<style>
.dp__theme_light {
  --dp-primary-color: var(--calendar-selected-background-color) !important;
  --dp-hover-color: var(--calendar-hover-color) !important;
  --dp-icon-color: var(--control-icon-color) !important;
  --dp-secondary-color: var(--calendar-text-color);
  --dp-disabled-color: #737373 !important;
  --dp-background-color: var(--calendar-background-color) !important;
}
</style>
