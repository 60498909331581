<template>
  <div class="container mt-3 mb-5" :style="style">
    <div class="row">
      <div class="col-12" style="pointer-events: none">
        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
          <div class="title col-lg-12 mb-2">
            <span class="title">
              {{ $t("please-verify-contact-information") }}:
            </span>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('name')"
                  :model-value="personalInfo.firstName"
                  name="name"
                  type="text"
                />
              </div>
              <div class="col-md-4 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('last-name')"
                  :model-value="personalInfo.lastName"
                  name="lastName"
                  type="text"
                />
              </div>
              <div class="col-md-4 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('second-last-name')"
                  :model-value="personalInfo.secondLastName"
                  name="secondLastName"
                  type="text"
                />
              </div>
              <div class="col-md-6 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('email')"
                  :model-value="personalInfo.email"
                  name="email"
                  type="text"
                />
              </div>
              <div class="col-md-6 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('confirm-email')"
                  :model-value="personalInfo.confirmEmail"
                  name="confirmEmail"
                  type="text"
                />
              </div>
              <div v-if="showTelephone" class="col-md-4 mb-4">
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('cellphone')"
                  mask="(###) ###-####"
                  :model-value="personalInfo.phone"
                  name="phone"
                  type="text"
                />
              </div>
              <div v-if="showTelephone" class="col-md-4 mb-4">
                <BaseSelect
                  :color="style['--control-text-color']"
                  :label="$t('provider')"
                  :model-value="personalInfo.carrierId"
                  name="carrierId"
                  :options="providers"
                />
              </div>
              <div class="col-md-4 mb-4" v-if='(plusClient && plusClient.showAttendeesNumber !== undefined) ? plusClient.showAttendeesNumber : true'>
                <base-input
                  :color="style['--control-text-color']"
                  :label="$t('attendees')"
                  :model-value="personalInfo.attendees"
                  name="attendees"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div
            v-if="showSpecialFieldsForm || showRequireApprovalCode"
            class="col-md-12"
          >
            <hr />
          </div>
          <div
            v-if="showSpecialFieldsForm || showRequireApprovalCode"
            class="title col-lg-12 mb-2"
          >
            <span class="title">
              {{ $t("please-verify-additional-information") }}:
            </span>
          </div>
          <div v-if="showSpecialFieldsForm" class="col-12">
            <div class="row">
              <div
                v-if="
                  (specialFields.specialField1Enabled &&
                    specialFields.specialField1ShowAptsWeb) ||
                  serviceSpecialFields.specialField1Enabled
                "
                class="col-md-4 mb-4"
              >
                <base-input
                  :color="style['--control-text-color']"
                  :label="
                    serviceSpecialFields.specialField1Name ||
                    specialFields.specialField1Name ||
                    `${$t('special-field')} #1`
                  "
                  :model-value="personalInfo.specialField1"
                  name="specialField1"
                  type="text"
                />
              </div>
              <div
                v-if="
                  specialFields.specialField2Enabled &&
                  specialFields.specialField2ShowAptsWeb
                "
                class="col-md-4 mb-4"
              >
                <base-input
                  :color="style['--control-text-color']"
                  :label="
                    specialFields.specialField2Name ||
                    `${$t('special-field')} #2`
                  "
                  :model-value="personalInfo.specialField2"
                  name="specialField2"
                  type="text"
                />
              </div>
              <div
                v-if="
                  specialFields.specialField3Enabled &&
                  specialFields.specialField3ShowAptsWeb
                "
                class="col-md-4 mb-4"
              >
                <BaseSelect
                  :color="style['--control-text-color']"
                  :label="
                    specialFields.specialField3Name ||
                    `${$t('special-field')} #3`
                  "
                  :model-value="personalInfo.specialField3"
                  name="specialField3"
                  :options="miscs"
                />
              </div>
              <div
                v-if="
                  specialFields.specialField4Enabled &&
                  specialFields.specialField4ShowAptsWeb
                "
                class="col-md-4 mb-4"
              >
                <base-input
                  :color="style['--control-text-color']"
                  :label="
                    specialFields.specialField4Name ||
                    `${$t('special-field')} #4`
                  "
                  :model-value="personalInfo.specialField4"
                  name="specialField4"
                  type="text"
                />
              </div>
              <div
                v-if="
                  specialFields.specialField5Enabled &&
                  specialFields.specialField5ShowAptsWeb
                "
                class="col-md-4 mb-4"
              >
                <base-input
                  :color="style['--control-text-color']"
                  :label="
                    specialFields.specialField5Name ||
                    `${$t('special-field')} #5`
                  "
                  :model-value="personalInfo.specialField5"
                  name="specialField5"
                  type="text"
                />
              </div>
              <div
                v-if="
                  specialFields.specialField6Enabled &&
                  specialFields.specialField6ShowAptsWeb
                "
                class="col-md-4 mb-4"
              >
                <base-input
                  :color="style['--control-text-color']"
                  :label="
                    specialFields.specialField6Name ||
                    `${$t('special-field')} #6`
                  "
                  :model-value="personalInfo.specialField6"
                  name="specialField6"
                  type="text"
                />
              </div>
              <div
                v-if="
                  (specialFields.specialField7Enabled &&
                    specialFields.specialField7ShowAptsWeb) ||
                  serviceSpecialFields.specialField7Enabled
                "
                class="col-md-4 mb-4"
              >
                <BaseDatePicker
                  :color="style['--control-text-color']"
                  :label="
                    serviceSpecialFields.specialField7Name ||
                    specialFields.specialField7Name ||
                    `${$t('special-field')} #7`
                  "
                  :model-value="personalInfo.specialField7"
                  name="specialField7"
                />
              </div>
            </div>
          </div>
          <div v-if="showRequireApprovalCode" class="col-12">
            <div class="row">
              <div class="col-md-4 mb-4">
                <base-input
                  :model-value="personalInfo.approvalCode"
                  class="no-spinners"
                  :color="style['--control-text-color']"
                  :label="`${$t('approvalCode')}`"
                  name="approvalCode"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12 mb-4">
        <label
          class="form-label"
          for="comment"
          :style="{ color: style['--control-text-color'] }"
        >
          {{ $t("comment") }}
        </label>
        <textarea
          id="comment"
          v-model="comment"
          class="form-control"
          maxlength="1028"
          rows="3"
        />
      </div>
      <div class="col-12">
        <div class="confirmation-content">
          <BigLoader v-if="loading" />
          <template v-else>
            <div v-if="errorMessage" class="error-message">
              <span v-html="errorMessage" />
              <br />
              <span v-if="appointmentNumber" class="extra-error-message">
                {{ $t("you-must-cancel-your-appointment-through") }}
                <span
                  class="appointment-cancellation"
                  @click="onCancelAppointment()"
                >
                  {{ $t("appointment-cancellation") }}
                </span>
                {{ $t("in-order-to-request-new-appointment") }}
              </span>
            </div>
            <div class="confirmation-message">
              {{
                $t(
                  errorMessage
                    ? "canceled-appointment-please-confirm"
                    : "sure-want-to-confirm-appointment"
                )
              }}
            </div>
            <div class="action-buttons">
              <button
                class="btn btn-primary me-2"
                :style="{
                  backgroundColor: style['--button-background-color-active'],
                  color: style['--button-text-color-active'],
                  borderColor: style['--button-border-color-active'],
                }"
                @click="onSubmit()"
              >
                {{ $t("yes") }}
              </button>
              <button
                class="btn"
                :style="{
                  backgroundColor: style['--button-background-color'],
                  color: style['--button-text-color'],
                  borderColor: style['--button-border-color'],
                }"
                @click="$emit('back')"
              >
                {{ $t("no") }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseDatePicker from "@/components/BaseDatePicker";
import BaseInput from "@/components/BaseInput.vue";
import { useRouter } from "vue-router";
import { confirmAppointment as _confirmAppointment } from "@/services/AppointmentService.js";
import BigLoader from "@/components/BigLoader.vue";
import { USER_SESSION } from "@/constants/SessionStorage";
import { useIPAddress } from "@/composables/useIPAddress";
import CompanyId from "@/constants/CompanyId";

export default defineComponent({
  components: {
    BaseSelect,
    BaseDatePicker,
    BaseInput,
    BigLoader,
  },
  props: {
    profile: {
      type: Object,
      value: null,
    },
    configuration: {
      type: Object,
      value: () => ({}),
    },
    confirmation: {
      type: Object,
      value: () => ({}),
    },
    personalInfo: {
      type: Object,
      value: () => ({}),
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
    plusClient: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const userSession = sessionStorage.getItem(USER_SESSION);
    const metadata = JSON.parse(userSession).metadata;

    const providers = ref(props.configuration.providers);
    const miscs = ref(props.configuration.miscs);
    const specialFields = ref(props.configuration.specialFields);
    const serviceSpecialFields = ref(props.configuration.serviceSpecialFields);

    const showSpecialFieldsForm = ref(
      props.configuration.showSpecialFieldsForm
    );

    const showRequireApprovalCode = ref(
      props.configuration.showRequireApprovalCode
    );

    const comment = ref(null);
    const loading = ref(false);
    const errorMessage = ref(null);
    const appointmentNumber = ref(null);
    const iPAddress = ref(null);

    const { getIPAddress } = useIPAddress();

    const showTelephone = computed(
      () => props.profile.companyId !== CompanyId.AFPCrecer
    );

    const onSubmit = async () => {
      errorMessage.value = null;
      loading.value = true;
      appointmentNumber.value = null;
      iPAddress.value = await getIPAddress();

      const model = {
        ...props.personalInfo,
        subServiceId:
          props.answer.service?.subservice?.id || metadata.subServiceId || null,
        clientAppointmentId: props.answer.space.clientAppointmentId,
        paternalLastName: props.personalInfo.lastName,
        maternalLastName: props.personalInfo.secondLastName,
        specialField3: props.personalInfo.specialField3
          ? props.personalInfo.specialField3.toString()
          : null,
        comment: comment.value,
        documents: props.answer.documents
          ? props.answer.documents
              .filter((x) => Boolean(x.file))
              .map((x) => ({ documentTypeId: x.documentTypeId, file: x.file }))
          : [],
        termsAndConditionsServiceTypeId:
          props.answer.mediaConsent?.termsAndConditionsServiceTypeId,
        validatePreviousCoordinated: props.personalInfo.email ? true : false,
        clientAppointmentParentId: props.personalInfo.approvalCode || null,
        iPAddress: iPAddress.value,
        rmo: metadata.rmo || null,
        adRequestNumber: metadata.adRequestNumber || null,
      };

      _confirmAppointment(model)
        .then(({ data }) => {
          emit("update:confirmation", data);
          emit("next");
        })
        .catch((err) => {
          errorMessage.value = err.response.data.message;
          if (errorMessage.value) {
            const split = errorMessage.value.split(" ");
            if (split[split.length - 1].includes("0171")) {
              let value = split[split.length - 2];
              value = value.replaceAll("<b>#", "");
              value = value.replaceAll("</b>.", "");
              appointmentNumber.value = value;
            }
          }
        })
        .finally(() => (loading.value = false));
    };

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--control-icon-color":
          props.designConfiguration?.formControlIconColor || "#ffa21a",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#343434",
        "--control-background-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "white",
        "--control-border-color":
          props.designConfiguration?.formControlFontColor || "#343434",
        "--button-text-color":
          props.designConfiguration?.buttonFontColor || "#343434",
        "--button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "transparent",
        "--button-border-color":
          props.designConfiguration?.buttonBorderColor || "#343434",
        "--button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    const onCancelAppointment = () => {
      const routeData = router.resolve({
        name: "CancelAppointment",
        query: {
          email: props.personalInfo.email,
          appointment: appointmentNumber.value,
        },
      });
      window.open(routeData.href, "_blank");
    };

    return {
      providers,
      specialFields,
      serviceSpecialFields,
      miscs,
      comment,
      showSpecialFieldsForm,
      onSubmit,
      loading,
      errorMessage,
      style,
      onCancelAppointment,
      appointmentNumber,
      showRequireApprovalCode,
      getIPAddress,
      showTelephone
    };
  },
});
</script>
<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: bold;
}

::v-deep {
  input,
  select {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.confirmation-content {
  border: 1px solid var(--control-border-color);
  background-color: var(--control-background-color);
  padding: 8px;
  border-radius: 4px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .error-message {
    font-size: 16px;
    color: #dc3545;
    text-align: center;
    margin-bottom: 10px;
  }

  .extra-error-message {
    color: #343434;
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
    .appointment-cancellation {
      cursor: pointer;
      color: var(--title-color);
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .confirmation-message {
    font-size: 16px;
    color: var(--control-text-color);
    font-weight: bold;
    text-align: center;
  }

  .action-buttons {
    margin-top: 15px;
    text-align: center;
  }
}
</style>
