export default {
  loading: "Cargando",
  government: "Gobierno",
  private: "Privado",
  selectCompany: "Seleccione una compañia",
  searchCompany: "Buscar compañia",
  searchService: "Buscar un servicio",
  continue: "Continuar",
  moreTimeForYou: "Más tiempo para ti",
  scheduleYourAppointmentHere: "Agendando tu cita aquí",
  service: "Servicio",
  location: "Localidad",
  dateTime: "Fecha y Hora",
  documents: "Documentos",
  personalInformation: "Datos Personales",
  confirmation: "Confirmación",
  selectA: "Seleccione un",
  selectCategory: "Seleccione Categoria",
  subservice: "Sub servicio",
  select: "Seleccionar",
  searchLocation: "Buscar una localidad",
  selectMunicipality: "Seleccione un municipio",
  seeLocation: "Ver localidad",
  goBack: "Regresar",
  locations: "Localidades",
  distance: "Distancia",
  kilometres: "kilometros",
  physicalAddress: "Dirección física",
  from: "Desde",
  to: "Hasta",
  today: "Hoy",
  cancel: "Cancelar",
  search: "Buscar",
  all: "Todo",
  noSpacesAvailable: "No hay espacios disponibles para esta fecha ({0})",
  noSearchPerformed: "No se ha realizado búsqueda.",
  toSearchClickTheBoton: "Para buscar haga clic en el botón con una lupa",
  selectedDateWasModified: "Se modificó la fecha seleccionada",
  available: "Disponible",
  noAvailable: "No disponible",
  legend: "Leyenda",
  upload: "Subir",
  "required-documents": "Documentos Requeridos",
  required: "Requerido",
  "press-drag-to-upload-documents": "Presione o arrastre para subir documentos",
  date: "Día",
  hour: "Hora",
  "no-required": "No requerido",
  "only-pdf-files-are-allowed": "Sólo se permiten archivos PDF, JPEG, JPG y PNG",
  complete: "Completar",
  "personal-information": "Datos personales",
  "additional-information": "Información adicional",
  name: "Nombre",
  "last-name": "Apellido paterno",
  "second-last-name": "Apellido materno",
  email: "Email",
  "confirm-email": "Confirmar email",
  cellphone: "Celular",
  provider: "Proveedor",
  "have-read-and-accept": "He leído y acepto los",
  "terms-conditions": "terminos y condiciones",
  "special-field": "Campo especial",
  "field-required": "Este campo es requerido",
  "please-verify-contact-information":
    "Favor verificar información de contacto",
  "please-verify-additional-information":
    "Favor verificar información adicional",
  "sure-want-to-confirm-appointment":
    "¿Está seguro que desea confirmar la cita?",
  no: "No",
  yes: "Sí",
  "confirm-appointment-contact":
    "Confirmar cita y verificar información de contacto",
  "information-selected-appointment":
    "La información de la cita seleccionada es la siguiente",
  comment: "Comentario",
  attendees: "Personas en cita",
  "confirmed-appointment": "Cita confirmada",
  "appointment-confirmation-number": "El número de confirmación de cita es",
  home: "Inicio",
  "more-time-for-you": "Más tiempo para ti",
  "schedule-your-appointment-here": "Agenda tu cita aquí",
  "cancel-appointment": "Cancelar cita",
  "select-category-services":
    "Debe seleccionar una categoría para poder ver los servicios",
  "appointment-number": "Número de cita",
  number: "Número",
  status: "Estatus",
  "reason-cancellation": "Razón de Cancelación",
  "appointment-cancelled": "Cita cancelada",
  "appointment-successfully-cancelled":
    "Su cita ha sido cancelada exitósamente.",
  "you-must-upload-all-required-documents":
    "Debe subir todos los documentos requeridos",
  "you-must-cancel-your-appointment-through":
    "Debe cancelar su cita a través de",
  "in-order-to-request-new-appointment": "para poder solicitar una cita nueva.",
  "appointment-cancellation": "Cancelación de Citas",
  "canceled-appointment-please-confirm":
    "Si ya cancelo su cita previa, favor de confirmar su nueva cita",
  faq: {
    "how-to-navigate": "¿Cómo navegar?",
    "how-to-navigate-response":
      " Navegar es muy fácil, podrás ver los servicios para los cuales se ofrecen las citas. Una vez seleccionado el servicio te  presentará servicios adicionales dentro de esa categoría, al seleccionarlos podrás ver los documentos requeridos e información relacionada. En la parte de abajo podrás oprimir el calendario para coordinar una cita.",
    "how-to-request-the-appointment": "¿Cómo solicitar la cita?",
    "how-to-request-the-appointment-response":
      "Seleccionas el servicio que te interesa, luego podrás ver uno o más sub servicios relacionados y procedes a seleccionar el que te interesa, luego de leer la información sobre los requisitos podrás oprimir el calendario para coordinar la cita.",
    "can-request-office": "¿Puedo solicitar la oficina que desee?",
    "can-request-office-response":
      "Sí, puedes seleccionar la oficina de tu preferencia, siempre y cuando ésta ofrezca los servicios de citas que deseas.",
    "appointment-the-hour-by-order-of-arrival":
      "¿La cita es por hora o por orden de llegada?",
    "appointment-the-hour-by-order-of-arrival-response":
      "Se hará todo lo posible por atender lo más cercano posible a la hora de tu cita. No aplica el orden de llegada.",
    "can-cancel-appointment": "¿Puedo cancelar una cita?",
    "can-cancel-appointment-response":
      "Sí, para cancelar su cita deberá presionar el enlace de CANCELAR CITA en la parte superior de la página. Ingresará su cuenta de usuario y el número de cita para proceder a confirmar la cancelación.",
  },
  fileSizeLimitMessage: 'El tamaño del archivo no debe exceder los 10 MB.',
  decline: "Rechazar",
  mediaConsentHeader: "Consentimiento para foto, audio y video",
  mediaConsent:
    "Certifico que expresamente acepto las condiciones de uso sobre foto, video y audio que se detallaron en el formulario que antecede, el cual doy fe de haber leído y entendido.",
  approvalCode: "Código de aprobación del examen",
  stamps: 'Sellos',
  office: 'Oficina',
  client: 'Client',
  stampsWarning: 'De no comprar los sellos su cita será cancelada',
  stampsValidationMessage: 'Para confirmar su cita y poder acceder al servicio usted debe tener los sellos necesarios o adquirirlos aquí. Usted puede comprar los sellos de esta transacción o entrar el número de los mismos si los tiene a mano. Esos sellos se procesan por Colecturía Digital. Si decidiera comprar los sellos aquí solo podrá obtener la totalidad de los sellos, no sellos parciales. Si desea proceder a entrar el número de sello o adquirirlos oprima el botón COMPRAR/VALIDAR SELLOS.',
  buyStamps: 'COMPRAR/VALIDAR',
  yourAppointmentNumberIs: 'El número de su Cita a confirmar es',
  avail: "Disp",
  noAvail: "No disp",
};
