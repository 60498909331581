<template>
  <div class="container" :style="style">
    <div class="wrapper d-flex align-items-center">
      <div
        v-for="(step, i) in steps"
        :key="i"
        class="stepper-item"
        :class="{
          finished: step.id <= value,
          active: step.id === value,
          disabled: step.disabled,
        }"
      >
        <div class="step">{{ step.id }}</div>
        <div class="content">
          <div class="title">{{ $t(step.name) }}</div>
          <div class="line" />
          <small v-if="step.disabled" style="font-size: 12px">
            {{ $t("no-required") }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, computed } from "vue";

export default defineComponent({
  name: "StepperComponent",
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props) {
    watch(
      () => props.value,
      () => {
        setTimeout(() => {
          const element = document.querySelector(".active");
          if (element) element.scrollIntoView({ behavior: "smooth" });
        }, 200);
      }
    );

    const style = computed(() => {
      return {
        "--header-background-color":
          props.designConfiguration?.tableHeaderBackgroundColor || "#ffa21a",
        "--header-text-color":
          props.designConfiguration?.tableHeaderFontColor || "#ffe2bb",
        "--header-completed-color":
          props.designConfiguration?.tableCompletedIconColor || "#272727",
      };
    });
    return {
      style,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  overflow-x: auto;
}
.wrapper {
  height: 130px;
  min-width: 950px;
}

.stepper-item {
  display: flex;
  flex-grow: 1;
  gap: 12px;
  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--header-text-color);
    font-size: 18px;
    color: var(--header-background-color);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    flex-grow: 1;
    .title {
      font-size: 14px;
      color: var(--header-text-color);
      font-weight: 600;
    }
    .line {
      height: 0;
      border: 1px solid var(--header-text-color);
      width: 90%;
    }
  }
  &.finished,
  &.active {
    .step {
      background-color: var(--header-completed-color);
    }
    .content {
      .title {
        color: var(--header-completed-color);
      }
      .line {
        border-color: var(--header-completed-color);
      }
    }
  }
  &.disabled {
    .step {
      background-color: #dddddd;
      color: #f5f5f5;
    }
    .content {
      .title {
        color: #dddddd;
      }
      .line {
        border-color: #dddddd;
      }
    }
  }
}
</style>
