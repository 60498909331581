<template>
  <!-- Mashead header-->
  <header>
    <div class="container px-5">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-7" :class="isMobile ? 'p-0' : ''">
          <!-- Mashead text and app badges-->
          <div class="mb-5 mb-lg-0 text-center text-lg-start">
            <h1 class="display-5 lh-1 mb-3 title-bold">
              {{ $t("more-time-for-you") }},
            </h1>
            <component :is="$i18n.locale == 'es' ? 'h1' : 'h2'">{{
              $t("schedule-your-appointment-here")
            }}</component>
          </div>
        </div>
        <div class="col-lg-5">
          <!-- Masthead device mockup feature-->
          <div class="masthead-device-mockup">
            <img class="img-fluid" src="../../assets/img/clock.svg" />
          </div>
        </div>
      </div>
    </div>
  </header>

  <filter-component
    v-model="search"
    :loading="loading"
    :types="collection.types"
    @category-change="category = $event"
  />

  <entity-component
    v-if="companies.length"
    v-show="!loading"
    :companies="companies"
    :loading="companyLoading"
  />

  <faq-component />
</template>

<script>
import FaqComponent from "./components/FaqComponent.vue";
import FilterComponent from "./components/FilterComponent.vue";
import EntityComponent from "./components/EntityComponent.vue";
import { onMounted, ref, computed, reactive, watch } from "vue";
import {
  getCompanies as _getCompanies,
  getTypes as _getTypes,
} from "@/services/PublicService";
import {
  USER_SESSION,
  PLUS_CLIENT,
  DESING_CONFIGURATION,
  QSINFO,
  MINIMUM_STEP,
} from "@/constants/SessionStorage";
import { removeAccents } from "@/core/helpers/text";

export default {
  name: "HomeView",
  components: {
    FaqComponent,
    FilterComponent,
    EntityComponent,
  },
  setup() {
    sessionStorage.removeItem(MINIMUM_STEP);
    sessionStorage.removeItem(QSINFO);
    sessionStorage.removeItem(USER_SESSION);
    sessionStorage.removeItem(PLUS_CLIENT);
    sessionStorage.removeItem(DESING_CONFIGURATION);

    const loading = ref(true);
    const companyLoading = ref(false);
    const category = ref(null);
    const collection = reactive({ companies: [], types: [] });
    const search = ref(null);

    const loadCompanies = async (typeId = null) => {
      companyLoading.value = true;
      await _getCompanies(typeId)
        .then(({ data }) => {
          collection.companies = data;
        })
        .finally(() => (companyLoading.value = false));
    };

    onMounted(async () => {
      document.querySelector("body").style.backgroundColor =
        "rgb(233 181 99 / 11%)";

      await _getTypes().then(({ data }) => {
        collection.types = [...data];
      });

      await loadCompanies();
      loading.value = false;
    });

    const companies = computed(() => {
      if (!search.value) return collection.companies;
      const term = removeAccents(search.value);

      return collection.companies.filter(
        (x) => removeAccents(x.name).indexOf(term) !== -1
      );
    });

    watch(
      () => category.value,
      (value) => {
        loadCompanies(value);
      }
    );

    return {
      companies,
      loading,
      category,
      collection,
      search,
      companyLoading,
    };
  },
  computed: {
    isMobile() {
      return screen.width <= 760;
    },
  },
};
</script>
<style>
.masthead-device-mockup {
  max-width: 332px;
}
</style>
