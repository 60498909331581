<template>
  <div class="form-group" :class="customClass" :style="style">
    <label
      v-if="label"
      :for="`input-${label}`"
      :style="{
        color: `${fontColor} !important`,
      }"
    >
      {{ label }}:
    </label>
    <div class="input-group">
      <div v-if="prependIcon" class="input-group-prepend">
        <span class="input-group-text">
          <i class="bi" :class="prependIcon" :style="{ color: colorIcon }" />
        </span>
      </div>
      <select
        :id="`input-${label}`"
        v-model="inputValue"
        class="form-select"
        :class="{
          prepend: prependIcon,
          'is-invalid': !!errorMessage,
          clearable: clearable && inputValue,
        }"
        :sync="true"
        @blur="handleBlur"
        @input="handleChange"
      >
        <option disabled :value="null">
          {{ placeholder || $t("select") }}
        </option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.id"
        >
          {{ option.name }}
        </option>
      </select>
      <div
        v-if="clearable && inputValue"
        class="input-group-append cursor-pointer"
        @click="handleChange(null)"
      >
        <span class="input-group-text">
          <i class="bi bi-x-lg" />
        </span>
      </div>
      <div v-if="!!errorMessage" class="invalid-feedback">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import { computed, toRef, reactive } from "vue";
import { useField } from "vee-validate";

export default {
  name: "BaseSelect",
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    weight: {
      type: Number,
      default: 400,
    },
    size: {
      type: String,
      default: "12pt",
    },
    color: {
      type: String,
      default: "#272727",
    },
    options: {
      type: Array,
      default: () => [],
    },
    customClass: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    colorIcon: {
      type: String,
      default: "#ffa21a",
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const fontWeight = computed(() => props.weight || 400);
    const fontSize = computed(() => props.size || "14pt");
    const fontColor = computed(() => props.color || "#272727");

    const maskBinded = reactive({});

    const name = toRef(props, "name");

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.modelValue,
    });

    const style = computed(() => {
      return {
        "--input-font-color": fontColor.value,
      };
    });

    return {
      fontWeight,
      fontSize,
      fontColor,
      inputValue,
      errorMessage,
      meta,
      handleBlur,
      handleChange,
      maskBinded,
      style,
    };
  },
};
</script>
<style lang="scss" scoped>
.invalid-feedback {
  position: absolute;
  max-width: 400px;
}
.form-group {
  .input-group {
    .input-group-prepend {
      border-right-width: 0;
      .input-group-text {
        background-color: white;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        i {
          color: #ffa21a;
        }
      }
    }
    .input-group-append {
      border-left-width: 0;
      .input-group-text {
        background-color: white;
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .form-select {
      &:focus {
        box-shadow: none;
        border-color: #ced4da;
      }
      &.prepend {
        border-left: 0;
      }
      &.clearable {
        border-right: 0;
      }
    }
  }

  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  &.size-lg {
    .input-group-prepend {
      .input-group-text {
        i::before {
          font-size: 20px;
          font-weight: bold !important;
        }
      }
    }
    .form-select {
      height: 60px;
      font-size: 18px;
      font-weight: 600;
      color: var(--input-font-color);
      &::placeholder {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .input-group-text {
      height: 60px;
    }
  }
}
</style>
