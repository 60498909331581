import appInsights from '@/main';

import { errorLoggerWithoutPromise } from "axios-logger/lib/logger/error";
import { requestLogger, responseLogger } from "axios-logger";
import { requestErrorHandler, responseErrorHandler } from "./error-handler";
import { authHandler } from "./auth-handler";
import { languageHandler } from "./language-handler";
import { DEBUG_LOG } from "@/config";

const debugLog = DEBUG_LOG === "true";

export const requestInterceptor = {
  onFulfilled: (config) => {
    authHandler(config);
    languageHandler(config);
    if (!debugLog) return config;
    return requestLogger(config);
  },

  onRejected: (error) => {
    requestErrorHandler(error);

    if (!debugLog) return error;

    return errorLoggerWithoutPromise(error);
  },
};

export const responseInterceptor = {
  onFulfilled(response) {
    if (!debugLog) return response;

    return responseLogger(response);
  },

  onRejected(error) {
    responseErrorHandler(error);

    if (!debugLog) return error;

    return errorLoggerWithoutPromise(error);
  },
};

let requests = {};

export const applicationInsightsRequestInterceptor = {
  onFulfilled: (config) => {
    // Generate a unique request ID (e.g., using URL and current time)
    config.metadata = { startTime: new Date().getTime() };

    const requestId = `${config.url}-${config.metadata.startTime}`;
    requests[requestId] = config.metadata.startTime;

    return config;
  },
  onRejected: (error) => {
    return Promise.reject(error);
  }
};

export const applicationInsightsResponseInterceptor = {
  onFulfilled: (response) => {
    // Retrieve the start time from the requests object
    const requestId = `${response.config.url}-${response.config.metadata.startTime}`;
    const startTime = requests[requestId];
    if (startTime) {
      const duration = new Date().getTime() - startTime;
      delete requests[requestId]; // Clean up

      // Track the request with Application Insights
      appInsights.trackDependencyData({
        id: requestId,
        target: response.config.url,
        name: 'HTTP GET',
        duration: duration,
        resultCode: response.status,
        success: true,
        data: response.config.url,
        type: 'Http'
      });
    }

    return response;
  },
  onRejected: (error) => {
    // Handle and track errors in a similar manner
    const config = error.config;
    const requestId = `${config.url}-${config.metadata.startTime}`;
    const startTime = requests[requestId];
    if (startTime) {
      const duration = new Date().getTime() - startTime;
      delete requests[requestId]; // Clean up

      appInsights.trackDependencyData({
        id: requestId,
        target: config.url,
        name: 'HTTP GET',
        duration: duration,
        resultCode: error.status,
        success: false,
        data: config.url,
        type: 'Http'
      });
    }
    
    return Promise.reject(error);
  }
};