<template>
  <!-- Navigation-->
  <nav id="mainNav" class="navbar navbar-expand-lg navbar-light" :style="style">
    <div class="container px-2">
      <a
        v-if="showLogo || title"
        class="navbar-brand fw-bold cursor-pointer"
        @click="$router.goHome()"
      >
        <img v-if="showLogo && logo" :src="logo" style="height: 3rem" />
        <span v-else-if="!showLogo" class="header-title">{{ title }}</span>
      </a>
      <a
        class="nav-link fw-bold pointer language d-block d-lg-none"
        @click="changeLanguage($i18n)"
      >
        <i class="bi bi-translate" />
        {{ $i18n.locale === "es" ? "Eng" : "Esp" }}
      </a>
      <button
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler border-0"
        data-bs-target="#navbarResponsive"
        data-bs-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon border-0" />
      </button>
      <div id="navbarResponsive" class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
          <li class="nav-item d-none d-lg-block">
            <a
              class="nav-link me-lg-3 fw-bold pointer language"
              @click="changeLanguage($i18n)"
            >
              <i class="bi bi-translate" />
              {{ $i18n.locale === "es" ? "English" : "Español" }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link me-lg-3 fw-bold pointer"
              @click="$router.goHome()"
            >
              {{ $t("home") }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link me-lg-3 fw-bold pointer"
              @click="goCancelAppointment()"
            >
              {{ $t("cancel-appointment") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { defineComponent, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DESING_CONFIGURATION } from "@/constants/SessionStorage";
import logoImage from "../assets/img/logo.svg";
import { getSVGBlob } from "@/core/helpers/file";

export default defineComponent({
  name: "TopNavbarComponent",
  setup() {
    const designConfiguration = ref(null);
    const logo = ref(logoImage);
    const backgroundImage = ref(null);
    const route = useRoute();
    const router = useRouter();

    watch(
      () => route.name,
      (value) => {
        if (
          !["createAppointment", "CancelAppointment", undefined].includes(value)
        ) {
          sessionStorage.removeItem(DESING_CONFIGURATION);
        }
        designConfiguration.value = sessionStorage.getItem(DESING_CONFIGURATION)
          ? JSON.parse(sessionStorage.getItem(DESING_CONFIGURATION))
          : {};
      },
      { immediate: true }
    );

    watch(
      () => designConfiguration.value,
      () => {
        if (designConfiguration.value?.plusClientHeaderImageFile) {
          const file = designConfiguration.value.plusClientHeaderImageFile;
          if (file.content && file.fileName.endsWith(".svg")) {
            const svgBlob = getSVGBlob(file.content);
            const reader = new FileReader();
            reader.onload = function () {
              logo.value = reader.result;
            };

            reader.readAsDataURL(svgBlob);
          } else logo.value = file.publicUrl;
        } else logo.value = logoImage;

        if (designConfiguration.value?.headerBackgroundImageFile) {
          const file = designConfiguration.value.headerBackgroundImageFile;
          if (file.content && file.fileName.endsWith(".svg")) {
            const svgBlob = getSVGBlob(file.content);
            const reader = new FileReader();
            reader.onload = function () {
              backgroundImage.value = reader.result;
            };

            reader.readAsDataURL(svgBlob);
          } else backgroundImage.value = file.publicUrl;
        } else backgroundImage.value = null;
      }
    );

    const changeLanguage = (i18n) => {
      const language = i18n.locale === "es" ? "en" : "es";

      i18n.locale = language;

      sessionStorage.setItem("locale", language === "es" ? "es-PR" : "en-US");
    };

    const goCancelAppointment = () => {
      router.push({ name: "CancelAppointment" });
    };

    const title = computed(
      () => designConfiguration.value?.headerBackgroundText
    );

    const showLogo = computed(() => {
      if (
        designConfiguration.value &&
        designConfiguration.value.noShowHeaderLogo
      ) {
        return !designConfiguration.value.noShowHeaderLogo;
      }

      return true;
    });

    const style = computed(() => {
      return {
        "--header-background-color":
          designConfiguration.value?.headerBackgroundColor || "transparent",
        "--header-border-color":
          designConfiguration.value?.headerTextColor || "#ffa21a",
        "--header-text-color":
          designConfiguration.value?.headerTextColor || "#21252",
        "--header-text-size":
          designConfiguration.value?.headerTextSize || "1.25rem",
        "--header-background-image": backgroundImage.value
          ? `url(${backgroundImage.value})`
          : "none",
      };
    });

    return {
      goCancelAppointment,
      logo,
      style,
      showLogo,
      title,
      changeLanguage,
    };
  },
});
</script>

<style lang="scss">
.navbar {
  background-color: var(--header-background-color);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: var(--header-background-image);
}
.nav-link {
  color: var(--header-text-color) !important;
}

.header-title {
  color: var(--header-text-color) !important;
  font-size: var(--header-text-size) !important;
}

.language {
  border: 2px solid var(--header-border-color);
  border-radius: 8px;
}
</style>
