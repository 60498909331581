<template>
  <div class="container mt-3 mb-5" :style="style">
    <big-loader v-if="loading" class="mt-5" />
    <div v-else class="row">
      <div class="title col-lg-12 mb-2">
        {{ $t("select") }} <span class="marked">{{ $t("location") }}</span>
      </div>
      <div class="col-md-7 mb-2 mb-md-0">
        <base-input
          v-model="search"
          clearable
          :color="style['--control-text-color']"
          :color-icon="style['--control-icon-color']"
          custom-class="size-lg"
          :placeholder="$t('searchLocation')"
          prepend-icon="bi-search"
          type="text"
        />
      </div>
      <div v-if="showMunicipalities" class="col-md-5">
        <base-select
          v-model="municipality"
          clearable
          :color="style['--control-text-color']"
          :color-icon="style['--control-icon-color']"
          custom-class="size-lg"
          :options="municipalities"
          :placeholder="$t('selectMunicipality')"
          prepend-icon="bi-geo-alt-fill"
        />
      </div>
      <div class="col-md-12 mt-4">
        <GoogleMap :api-key="apiKey" :center="center" class="map" :zoom="zoom">
          <GoogleMarker
            v-if="currentLocation"
            :options="{
              position: {
                lat: currentLocation.latitude,
                lng: currentLocation.longitude,
              },
              icon: {
                url: icon,
                scaledSize: {
                  width: 35,
                  height: 40,
                },
              },
            }"
          />
          <GoogleMarker
            v-for="(location, index) in markers"
            :key="index"
            :options="{
              position: { lat: location.latitude, lng: location.longitude },
            }"
          >
            <InfoWindow>
              <div class="info-window-content">
                <div class="title">{{ location.name }}</div>
                <div class="sub-title">{{ location.address }}</div>
                <button
                  class="btn btn-primary btn-sm mt-2 float-end"
                  tabindex="-1"
                  @click="onSelect(location)"
                >
                  {{ $t("select") }}
                </button>
              </div>
            </InfoWindow>
          </GoogleMarker>
        </GoogleMap>
        <hr class="my-4" />
      </div>
      <div class="col-md-12 location-total mb-3">
        {{ locations.length }} {{ $t("locations") }}
      </div>
      <div class="col-md-12">
        <div class="d-flex align-items-center location-container">
          <button
            v-if="swiperRef && !swiperRef.isBeginning"
            class="btn action-button prev"
            @click="prepend()"
          >
            <i class="bi bi-chevron-compact-left" />
          </button>
          <button
            v-if="swiperRef && !swiperRef.isEnd"
            class="btn action-button next"
            @click="append()"
          >
            <i class="bi bi-chevron-compact-right" />
          </button>
          <swiper
            class="mySwiper"
            :modules="modules"
            :pagination="{
              clickable: true,
            }"
            :slidesPerView="slidesPerView"
            :spaceBetween="spaceBetween"
            @swiper="setSwiperRef"
          >
            <swiper-slide v-for="(option, index) in options" :key="index">
              <div class="row">
                <div
                  v-for="(location, i) in option"
                  :key="i"
                  class="col-md-12 mb-2"
                >
                  <div class="location-card p-4 d-flex flex-column">
                    <div class="information">
                      <h6>{{ location.name }}</h6>
                      <div class="text mb-2">
                        {{ location.address }}
                      </div>
                    </div>
                    <div class="actions">
                      <div class="row">
                        <div v-if="location.distance" class="col-md-12">
                          <span class="distance-title"
                            >{{ $t("distance") }}:
                          </span>
                          <span class="distance-subtitle">
                            {{ location.distance }} {{ $t("kilometres") }}
                          </span>
                        </div>
                        <div
                          v-if="location.longitude && location.latitude"
                          class="col-md-6 col-xl-5 pt-2 pe-0 cursor-pointer"
                          @click="
                            zoomLocation({
                              lat: location.latitude,
                              lng: location.longitude,
                            })
                          "
                        >
                          <i class="bi bi-geo-alt-fill mr-2" />
                          <span class="location-link">{{
                            $t("seeLocation")
                          }}</span>
                        </div>
                        <div
                          :class="{
                            'col-md-6  col-xl-7 ps-0':
                              location.longitude && location.latitude,
                            'col-12': !location.longitude || !location.latitude,
                          }"
                        >
                          <button
                            class="btn btn-primary select-button float-end"
                            @click="onSelect(location)"
                          >
                            {{ $t("select") }}
                            <i class="bi bi-arrow-right-circle" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div v-if="isGoBackVisible" class="col-md-12 mt-3">
        <button
          class="btn"
          :style="{
            backgroundColor: style['--return-button-background-color'],
            color: style['--return-button-text-color'],
            borderColor: style['--return-button-border-color'],
          }"
          @click="$emit('back')"
        >
          <i class="bi bi-arrow-left-circle" /> {{ $t("goBack") }}
        </button>
      </div>

      <div class="col-md-12">
        <MediaConsentModal
          :modalActive="
            this.mediaConsent?.termsAndConditionsServiceTypeId &&
            !this.mediaConsent.accepted
          "
          :modalAccept="true"
          :htmlContent="this.mediaConsent?.descriptionHtml"
          @accept="acceptMediaConsent"
          @decline="$emit('back')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import MediaConsentModal from "@/components/MediaConsentModal.vue";
import googlePersonIcon from "@/assets/img/google-maps-person-icon.jpeg";
import { MINIMUM_STEP, USER_SESSION } from "@/constants/SessionStorage";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import { useGeolocation } from "@/composables/useGeolocation";
import { useScrollable } from "@/composables/useScrollable";
import {
  getByServiceId as _getByServiceId,
  getBySubServiceId as _getBySubServiceId,
} from "@/services/LocationService";
import BigLoader from "@/components/BigLoader.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { removeAccents } from "@/core/helpers/text";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import CompanyId from "@/constants/CompanyId";
import { getTermsAndConditions as _getTermsAndConditions } from "@/services/AppointmentService";

export default defineComponent({
  name: "LocationScreen",
  components: {
    GoogleMap,
    GoogleMarker: Marker,
    BaseInput,
    BaseSelect,
    BigLoader,
    InfoWindow,
    Swiper,
    SwiperSlide,
    MediaConsentModal,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      value: null,
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
    mediaConsent: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
  },
  setup(props, { emit }) {
    emit("update:answer", null);

    const userSession = sessionStorage.getItem(USER_SESSION);
    const metadata = JSON.parse(userSession).metadata;
    const isDR = metadata.countries.some((x) => x == "RD");
    const defaultCenter = isDR
      ? { lat: 18.8016668, lng: -70.383973 }
      : { lat: 18.2226994, lng: -66.3051289 };
    const defaultZoom = isDR ? 8.3 : 9.8;

    const center = ref(defaultCenter);
    const zoom = ref(defaultZoom);
    const search = ref("");
    const municipality = ref(null);
    const locationList = ref(props.configuration || []);
    const loading = ref(false);
    const slider = ref(null);
    const windowWidth = ref(window.innerWidth);
    const swiperRef = ref();

    const { getCurrentLocation, getDistance, currentLocation } =
      useGeolocation();
    // eslint-disable-next-line no-undef
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_KEY;
    const icon = googlePersonIcon;

    const showMunicipalities = computed(
      () => props.profile.companyId !== CompanyId.AFPCrecer
    );

    window.addEventListener("resize", () => {
      windowWidth.value = window.innerWidth;
    });

    onMounted(async () => {
      useScrollable(slider);
      if (locationList.value && locationList.value.length) return;
      loading.value = true;
      await getCurrentLocation;
      const request = !props.service.subservice
        ? _getByServiceId(
            props.profile.companyId,
            props.service.value.id,
            metadata.rmo
          )
        : _getBySubServiceId(
            props.profile.companyId,
            props.service.value.id,
            props.service.subservice.id
          );

      request
        .then(({ data }) => {
          locationList.value = data.map((x) => {
            const latitude = Number(x.latitude) || null;
            const longitude = Number(x.longitude) || null;
            return {
              ...x,
              latitude,
              longitude,
              distance: getDistance(latitude, longitude),
            };
          });
          emit("update:configuration", locationList.value);
        })
        .finally(() => (loading.value = false));

      await _getTermsAndConditions(props.service.value.id).then(({ data }) => {
        emit("update:mediaConsent", { ...data });
      });
    });

    const isGoBackVisible = computed(() => {
      const minimumStep = sessionStorage.getItem(MINIMUM_STEP);

      return minimumStep != "2";
    });

    const locations = computed(() => {
      if (!search.value && !municipality.value)
        return [...locationList.value].sort((a, b) => a.distance - b.distance);
      const term = removeAccents(search.value);

      return locationList.value
        .filter((x) => {
          const name = removeAccents(x.name);
          let isValid = true;

          if (term && !name.includes(term)) isValid = false;

          if (municipality.value && x.municipalityName !== municipality.value)
            isValid = false;

          return isValid;
        })
        .sort((a, b) => a.distance - b.distance);
    });

    const groupByN = (n, arr) => {
      let result = [];
      for (let i = 0; i < arr.length; i += n) result.push(arr.slice(i, i + n));
      return result;
    };

    const slidesPerView = computed(() => {
      if (windowWidth.value <= 540) return 1;
      if (windowWidth.value <= 767) return 2;

      if (windowWidth.value <= 1200) return 2;

      return 3;
    });

    const baseInputSize = computed(() => {
      if (windowWidth.value <= 400) return "12px";

      return "12pt";
    });

    const options = computed(() => {
      return groupByN(3, locations.value);
    });

    const setSwiperRef = (swiper) => {
      swiperRef.value = swiper;
    };
    const append = () => {
      swiperRef.value.slideNext();
    };
    const prepend = () => {
      swiperRef.value.slidePrev();
    };

    const municipalities = computed(() => {
      return [...new Set(locationList.value.map((x) => x.municipalityName))]
        .sort()
        .map((x) => ({ id: x, name: x }));
    });
    const markers = computed(() => {
      return locations.value.filter((x) => x.latitude && x.longitude);
    });

    const acceptMediaConsent = () => {
      emit("update:mediaConsent", { ...props.mediaConsent, accepted: true });
    };

    watch(
      () => search.value,
      (value) => {
        if (!value) return;
        municipality.value = null;
      }
    );

    watch(
      () => municipality.value,
      (value) => {
        if (value) {
          const location = locations.value.find(
            (x) => x.municipalityName === value
          );
          if (location && location.latitude && location.longitude) {
            center.value = { lat: location.latitude, lng: location.longitude };
            zoom.value = 12;
            return;
          }
        }

        zoom.value = defaultZoom;
        center.value = defaultCenter;
      }
    );

    const zoomLocation = (coords) => {
      center.value = coords;
      zoom.value = 12;
    };

    const onSelect = (location) => {
      emit("update:answer", {
        ...location,
      });
      emit("next");
    };

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--button-text-color":
          props.designConfiguration?.buttonFontColor || "#ffa21a",
        "--button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "white",
        "--button-border-color":
          props.designConfiguration?.buttonBorderColor || "#ffa21a",
        "--button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#272727",
        "--control-icon-color":
          props.designConfiguration?.formControlIconColor || "#ffa21a",
        "--control-secondary-text-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "#212529",
        "--control-background-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "white",
        "--return-button-text-color":
          props.designConfiguration?.buttonFontColor || "#343434",
        "--return-button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "transparent",
        "--return-button-border-color":
          props.designConfiguration?.buttonBorderColor || "#343434",
      };
    });

    return {
      center,
      zoom,
      apiKey,
      search,
      municipality,
      municipalities,
      loading,
      locations,
      zoomLocation,
      onSelect,
      icon,
      currentLocation,
      slider,
      style,
      slidesPerView,
      spaceBetween: 10,
      options,
      swiperRef,
      setSwiperRef,
      append,
      prepend,
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
      markers,
      acceptMediaConsent,
      isGoBackVisible,
      baseInputSize,
      metadata,
      showMunicipalities
    };
  },
});
</script>
<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: normal;
  .marked {
    font-weight: bold;
  }
}

.map {
  width: 100%;
  height: 450px;
}

.scrolling-wrapper {
  overflow-x: auto;
}

.info-window-content {
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #ffa21a;
  }
}

.location-total {
  font-size: 18px;
  font-weight: bold;
  color: var(--title-color);
}

.mySwiper {
  width: 95%;
}
.location-container {
  position: relative;
  .location-card {
    border: 1px solid #dddddd;
    background: var(--control-background-color);
    border-radius: 4px;
    min-height: 197px;
    justify-content: space-between;
    color: var(--control-text-color);
    .text {
      font-size: 14px;
    }
    .distance-title {
      color: var(--control-secondary-text-color);
      font-size: 14px;
      font-weight: bold;
    }
    .distance-subtitle {
      font-size: 12px;
    }
    .location-link {
      text-decoration: underline;
      font-size: 14px;
      margin-left: 6px;
    }
  }
}

.select-button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color);
  border-color: var(--button-border-color);
  &:hover {
    background-color: var(--button-background-color-active) !important;
    color: var(--button-text-color-active);
    border-color: var(--button-border-color-active);
  }
}

::v-deep .swiper-pagination-bullet-active {
  background: #ffa21a;
}

.action-button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color);
  border-color: var(--button-border-color);
  height: 60px;
  width: 60px;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}

.action-button > i {
  font-size: 32px;
}

.next {
  right: -20px;
}
.prev {
  left: -20px;
}
.action-button:hover {
  background-color: var(--button-background-color-active) !important;
  color: var(--button-text-color-active);
  border-color: var(--button-border-color-active);
  box-shadow: none;
}
.action-button:focus,
.action-button:active {
  background-color: var(--button-background-color-active) !important;
  color: var(--button-text-color-active);
  border-color: var(--button-border-color-active);
  color: white;
  box-shadow: none;
}

@media screen and (max-width: 992px) {
  .location-card {
    min-height: 197px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .location-card {
    min-height: 241px !important;
  }
}

@media screen and (max-width: 400px) {
  ::v-deep {
    .form-group {
      .form-select {
        font-size: 14px !important;
      }
      .form-control {
        &::placeholder {
          font-size: 14px !important;
        }
      }
    }
  }
  .action-button {
    height: 50px;
    width: 50px;
  }
  .action-button > i {
    font-size: 26px;
  }
  .next {
    right: -35px;
  }
  .prev {
    left: -35px;
  }
}
</style>
