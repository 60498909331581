<template>
  <div class="container mt-3 mb-5" :style="style">
    <big-loader v-if="loading" class="mt-5" />

    <div v-else class="row">
      <div class="title col-lg-12 mb-2">
        {{ $t("complete") }}
        <span class="marked">{{ $t("personal-information") }}</span>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 mb-4">
            <base-input
              v-model="model.firstName"
              :color="style['--control-text-color']"
              :label="$t('name')"
              :disabled="firstNameDisabled"
              name="name"
              type="text"
            />
          </div>
          <div class="col-md-4 mb-4">
            <base-input
              v-model="model.lastName"
              :disabled="lastNameDisabled"
              :color="style['--control-text-color']"
              :label="$t('last-name')"
              name="lastName"
              type="text"
            />
          </div>
          <div class="col-md-4 mb-4">
            <base-input
              v-model="model.secondLastName"
              :disabled="secondLastNameDisabled"
              :color="style['--control-text-color']"
              :label="$t('second-last-name')"
              name="secondLastName"
              type="text"
            />
          </div>
          <div class="col-md-6 mb-4">
            <base-input
              v-model="model.email"
              :disabled="emailDisabled"
              :color="style['--control-text-color']"
              :label="$t('email')"
              name="email"
              type="text"
            />
          </div>
          <div class="col-md-6 mb-4">
            <base-input
              v-model="model.confirmEmail"
              :disabled="emailDisabled"
              :color="style['--control-text-color']"
              :label="$t('confirm-email')"
              name="confirmEmail"
              type="text"
              :disablePaste="plusClient?.disablePasteOnEmailConfirmation"
            />
          </div>
          <div v-if="showTelephone" class="col-md-4 mb-4">
            <base-input
              :color="style['--control-text-color']"
              :label="$t('cellphone')"
              mask="(###) ###-####"
              :model-value="model.phone"
              :disabled="phoneDisabled"
              name="phone"
              type="text"
              @unmasked="model.phone = $event"
            />
          </div>
          <div v-if="showTelephone" class="col-md-4 mb-4">
            <BaseSelect
              v-model="model.carrierId"
              :color="style['--control-text-color']"
              :label="$t('provider')"
              name="carrierId"
              :options="providers"
            />
          </div>
          <div
            class="col-md-4 mb-4"
            v-if="
              plusClient && plusClient.showAttendeesNumber !== undefined
                ? plusClient.showAttendeesNumber
                : true
            "
          >
            <base-input
              v-model="model.attendees"
              :color="style['--control-text-color']"
              :label="$t('attendees')"
              name="attendees"
              type="number"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showSpecialFieldsForm || showRequireApprovalCode"
        class="title col-lg-12 mb-2"
      >
        {{ $t("complete") }}
        <span class="marked">{{ $t("additional-information") }}</span>
      </div>
      <div v-if="showSpecialFieldsForm" class="col-12">
        <div class="row">
          <div
            v-if="
              (specialFields.specialField1Enabled &&
                specialFields.specialField1ShowAptsWeb) ||
              serviceSpecialFields.specialField1Enabled
            "
            class="col-md-4 mb-4"
          >
            <base-input
              v-model="model.specialField1"
              :color="style['--control-text-color']"
              :label="
                serviceSpecialFields.specialField1Name ||
                specialFields.specialField1Name ||
                `${$t('special-field')} #1`
              "
              name="specialField1"
              type="text"
              :mask="specialFields.specialField1Mask"
            />
          </div>
          <div
            v-if="
              specialFields.specialField2Enabled &&
              specialFields.specialField2ShowAptsWeb
            "
            class="col-md-4 mb-4"
          >
            <base-input
              v-model="model.specialField2"
              :color="style['--control-text-color']"
              :label="
                specialFields.specialField2Name || `${$t('special-field')} #2`
              "
              name="specialField2"
              type="text"
              :mask="specialFields.specialField2Mask"
            />
          </div>
          <div
            v-if="
              specialFields.specialField3Enabled &&
              specialFields.specialField3ShowAptsWeb
            "
            class="col-md-4 mb-4"
          >
            <BaseSelect
              v-model="model.specialField3"
              :color="style['--control-text-color']"
              :label="
                specialFields.specialField3Name || `${$t('special-field')} #3`
              "
              name="specialField3"
              :options="miscs"
            />
          </div>
          <div
            v-if="
              specialFields.specialField4Enabled &&
              specialFields.specialField4ShowAptsWeb
            "
            class="col-md-4 mb-4"
          >
            <base-input
              v-model="model.specialField4"
              :color="style['--control-text-color']"
              :label="
                specialFields.specialField4Name || `${$t('special-field')} #4`
              "
              name="specialField4"
              type="text"
              :mask="specialFields.specialField4Mask"
            />
          </div>
          <div
            v-if="
              specialFields.specialField5Enabled &&
              specialFields.specialField5ShowAptsWeb
            "
            class="col-md-4 mb-4"
          >
            <base-input
              v-model="model.specialField5"
              :color="style['--control-text-color']"
              :label="
                specialFields.specialField5Name || `${$t('special-field')} #5`
              "
              name="specialField5"
              type="text"
              :mask="specialFields.specialField5Mask"
            />
          </div>
          <div
            v-if="
              specialFields.specialField6Enabled &&
              specialFields.specialField6ShowAptsWeb
            "
            class="col-md-4 mb-4"
          >
            <base-input
              v-model="model.specialField6"
              :color="style['--control-text-color']"
              :label="
                specialFields.specialField6Name || `${$t('special-field')} #6`
              "
              name="specialField6"
              type="text"
              :mask="specialFields.specialField6Mask"
            />
          </div>
          <div
            v-if="
              (specialFields.specialField7Enabled &&
                specialFields.specialField7ShowAptsWeb) ||
              serviceSpecialFields.specialField7Enabled
            "
            class="col-md-4 mb-4"
          >
            <BaseDatePicker
              v-model="model.specialField7"
              :color="style['--control-text-color']"
              :label="
                serviceSpecialFields.specialField7Name ||
                specialFields.specialField7Name ||
                `${$t('special-field')} #7`
              "
              name="specialField7"
            />
          </div>
        </div>
      </div>
      <div v-if="showRequireApprovalCode" class="col-12">
        <div class="row">
          <div class="col-md-4 mb-4">
            <base-input
              v-model="model.approvalCode"
              class="no-spinners"
              :color="style['--control-text-color']"
              :label="`${$t('approvalCode')}`"
              name="approvalCode"
              type="number"
            />
          </div>
          <div v-if="validationMessage !== ''" class="col-md-8 mb-4 pt-4">
            <span style="color: red">
              {{ validationMessage }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 my-2">
        <div class="terms">
          <BaseCheckbox
            id="terms"
            v-model="terms"
            :color="designConfiguration?.checkboxColor || 'white'"
            :color-checked="designConfiguration?.checkboxMarkColor"
            name="termsConditions"
            size="16px"
            :weight="900"
          >
            {{ $t("have-read-and-accept") }}
            <span
              style="text-decoration: underline; cursor: pointer"
              :style="{ color: designConfiguration?.hyperLinkColor || 'white' }"
              @click="showTerms = true"
            >
              {{ $t("terms-conditions") }}
            </span>
          </BaseCheckbox>
        </div>
      </div>
      <div class="col-12 mt-5 d-flex justify-content-between">
        <button
          class="btn"
          :style="{
            backgroundColor: style['--return-button-background-color'],
            color: style['--return-button-text-color'],
            borderColor: style['--return-button-border-color'],
          }"
          @click="$emit('back')"
        >
          <i class="bi bi-arrow-left-circle" /> {{ $t("goBack") }}
        </button>
        <button
          class="btn"
          :style="{
            backgroundColor: style['--return-button-background-color-active'],
            color: style['--return-button-text-color-active'],
            borderColor: style['--return-button-border-color-active'],
          }"
          @click="onSubmit()"
        >
          {{ $t("continue") }} <i class="bi bi-arrow-right-circle" />
        </button>
      </div>
    </div>
  </div>
  <TurnosPRTermsModal v-model="showTerms" />
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from "vue";
import BigLoader from "@/components/BigLoader.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import TurnosPRTermsModal from "@/components/TurnosPRTermsModal.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseDatePicker from "@/components/BaseDatePicker";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { getCarriers as _getCarriers } from "@/services/CarrierService.js";
import {
  getSpecialFields as _getSpecialFields,
  getMiscData as _getMiscData,
} from "@/services/LocationService.js";
import {
  getSpecialFields as _getServiceSpecialFields,
  getApprovalCodeValidation as _getApprovalCodeValidation,
} from "@/services/ServicesService.js";
import CompanyId from "@/constants/CompanyId";

export default defineComponent({
  components: {
    BaseInput,
    BaseCheckbox,
    TurnosPRTermsModal,
    BigLoader,
    BaseSelect,
    BaseDatePicker,
  },
  props: {
    profile: {
      type: Object,
      value: null,
    },
    metadata: {
      type: Object,
      value: null,
    },
    service: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      value: () => ({}),
    },
    answer: {
      type: Object,
      value: () => ({}),
    },
    designConfiguration: {
      type: Object,
      value: () => ({}),
    },
    plusClient: {
      type: Object,
      value: () => ({}),
    },
  },
  emits: ["back", "next"],
  setup(props, { emit }) {
    const loading = ref(false);
    const providers = ref([]);
    const miscs = ref([]);
    const specialFields = ref({});
    const serviceSpecialFields = ref({});
    const showRequireApprovalCode = ref(false);

    const showSpecialFieldsForm = computed(() => {
      return Boolean(
        (specialFields.value.specialField1Enabled &&
          specialFields.value.specialField1ShowAptsWeb) ||
          (specialFields.value.specialField2Enabled &&
            specialFields.value.specialField2ShowAptsWeb) ||
          (specialFields.value.specialField3Enabled &&
            specialFields.value.specialField3ShowAptsWeb) ||
          (specialFields.value.specialField4Enabled &&
            specialFields.value.specialField4ShowAptsWeb) ||
          (specialFields.value.specialField5Enabled &&
            specialFields.value.specialField5ShowAptsWeb) ||
          (specialFields.value.specialField6Enabled &&
            specialFields.value.specialField6ShowAptsWeb) ||
          (specialFields.value.specialField7Enabled &&
            specialFields.value.specialField7ShowAptsWeb) ||
          serviceSpecialFields.value.specialField1Enabled ||
          serviceSpecialFields.value.specialField7Enabled
      );
    });

    const showTelephone = computed(
      () => props.profile.companyId !== CompanyId.AFPCrecer
    );

    const schema = computed(() => {
      if (loading.value) return {};

      const specialFieldValidation = {
        specialField1: yup.string().nullable().max(50),
        specialField2: yup.string().nullable().max(50),
        specialField3: yup.string().nullable(),
        specialField4: yup.string().nullable().max(50),
        specialField5: yup.string().nullable().max(50),
        specialField6: yup.string().nullable().max(50),
        specialField7: yup.string().nullable(),
      };

      if (
        (specialFields.value.specialField1Enabled &&
          specialFields.value.specialField1ShowAptsWeb &&
          specialFields.value.specialField1Required) ||
        (serviceSpecialFields.value.specialField1Enabled &&
          serviceSpecialFields.value.specialField1Required)
      ) {
        specialFieldValidation.specialField1 =
          specialFieldValidation.specialField1.required();
      }

      if (
        specialFields.value.specialField2Enabled &&
        specialFields.value.specialField2ShowAptsWeb &&
        specialFields.value.specialField2Required
      ) {
        specialFieldValidation.specialField2 =
          specialFieldValidation.specialField2.required();
      }

      if (
        specialFields.value.specialField3Enabled &&
        specialFields.value.specialField3ShowAptsWeb &&
        specialFields.value.specialField3Required
      ) {
        specialFieldValidation.specialField3 =
          specialFieldValidation.specialField3.required();
      }

      if (
        specialFields.value.specialField4Enabled &&
        specialFields.value.specialField4ShowAptsWeb &&
        specialFields.value.specialField4Required
      ) {
        specialFieldValidation.specialField4 =
          specialFieldValidation.specialField4.required();
      }

      if (
        specialFields.value.specialField5Enabled &&
        specialFields.value.specialField5ShowAptsWeb &&
        specialFields.value.specialField5Required
      ) {
        specialFieldValidation.specialField5 =
          specialFieldValidation.specialField5.required();
      }

      if (
        specialFields.value.specialField6Enabled &&
        specialFields.value.specialField6ShowAptsWeb &&
        specialFields.value.specialField6Required
      ) {
        specialFieldValidation.specialField6 =
          specialFieldValidation.specialField6.required();
      }

      if (
        (specialFields.value.specialField7Enabled &&
          specialFields.value.specialField7ShowAptsWeb &&
          specialFields.value.specialField7Required) ||
        (serviceSpecialFields.value.specialField7Enabled &&
          serviceSpecialFields.value.specialField7Required)
      ) {
        specialFieldValidation.specialField7 =
          specialFieldValidation.specialField7.required();
      }

      return yup.object({
        name: yup.string().required().nullable().max(50),
        lastName: yup.string().required().nullable().max(50),
        secondLastName: yup.string().nullable().max(50),
        email: props.metadata.emailRequired
          ? yup.string().required().max(256).email()
          : yup.string().nullable().max(256).email(),
        confirmEmail: yup.string().when("email", {
          is: (email) => email && email.length > 0,
          then: yup
            .string()
            .required()
            .oneOf([yup.ref("email"), null]),
          otherwise: yup
            .string()
            .nullable()
            .oneOf([yup.ref("email"), null]),
        }),
        ...(() => {
          if (showTelephone.value) {
            return {
              phone: yup.string().required().length(14),
              carrierId: yup.string().required().nullable(),
            };
          }
          return {};
        })(),
        attendees: yup.number().required().min(1),
        termsConditions: yup.boolean().required(),
        approvalCode: yup
          .number()
          [showRequireApprovalCode.value ? "required" : "notRequired"]()
          .nullable(),
        ...specialFieldValidation,
      });
    });

    const { validate } = useForm({
      initialValues: {
        firstName: props.answer.firstName || props.metadata.firstName || null,
        lastName: props.answer.lastName || props.metadata.lastName || null,
        secondLastName:
          props.answer.secondLastName || props.metadata.secondLastName || null,
        email: props.answer.email || props.metadata.email || null,
        confirmEmail: props.answer.confirmEmail || props.metadata.email || null,
        phone: props.answer.phone || props.metadata.phone || null,
        carrierId: props.answer.carrierId || null,
        attendees: props.answer.attendees || 1,
        specialField1:
          props.answer.specialField1 || props.metadata.misc1 || null,
        specialField2: props.answer.specialField2 || null,
        specialField3: props.answer.specialField3 || null,
        specialField4:
          props.answer.specialField4 || props.metadata.misc4 || null,
        specialField5:
          props.answer.specialField5 || props.metadata.misc5 || null,
        specialField6: props.answer.specialField6 || null,
        specialField7: props.answer.specialField7 || null,
        approvalCode: props.answer.approvalCode || null,
      },
      validationSchema: schema,
    });

    const model = reactive({
      firstName: props.answer.firstName || props.metadata.firstName || null,
      lastName: props.answer.lastName || props.metadata.lastName || null,
      secondLastName:
        props.answer.secondLastName || props.metadata.secondLastName || null,
      email: props.answer.email || props.metadata.email || null,
      confirmEmail: props.answer.confirmEmail || props.metadata.email || null,
      phone: props.answer.phone || props.metadata.phone || null,
      carrierId: props.answer.carrierId || null,
      attendees: props.answer.attendees || 1,
      specialField1: props.answer.specialField1 || props.metadata.misc1 || null,
      specialField2: props.answer.specialField2 || null,
      specialField3: props.answer.specialField3 || null,
      specialField4: props.answer.specialField4 || props.metadata.misc4 || null,
      specialField5: props.answer.specialField5 || props.metadata.misc5 || null,
      specialField6: props.answer.specialField6 || null,
      specialField7: props.answer.specialField7 || null,
      approvalCode: props.answer.approvalCode || null,
    });

    // Disable the field if the corresponding prop is defined.
    const firstNameDisabled = ref(
      props.metadata.firstName !== null &&
        props.metadata.firstName !== undefined
    );
    const lastNameDisabled = ref(
      props.metadata.lastName !== null && props.metadata.lastName !== undefined
    );
    const secondLastNameDisabled = ref(
      props.metadata.secondLastName !== null &&
        props.metadata.secondLastName !== undefined
    );
    const emailDisabled = ref(
      props.metadata.email !== null && props.metadata.email !== undefined
    );
    const phoneDisabled = ref(
      props.metadata.phone !== null && props.metadata.phone !== undefined
    );

    const showTerms = ref(false);
    const terms = ref(false);
    const validationMessage = ref("");

    const onSubmit = async () => {
      const validationResult = await validate();

      let { valid } = validationResult;
      if (!valid) return;

      if (showRequireApprovalCode.value === true) {
        validationMessage.value = "";
        await _getApprovalCodeValidation(
          props.profile.companyId,
          model.approvalCode,
          props.service.value.id
        ).then((resp) => {
          if (resp.data) {
            valid = resp.data.isValid;
            if (!valid) {
              validationMessage.value = resp.data.message;
            }
          }
        });
      }

      if (!valid) return;
      emit("update:answer", {
        ...model,
      });

      emit("update:configuration", {
        miscs: miscs.value,
        providers: providers.value,
        specialFields: specialFields.value,
        serviceSpecialFields: serviceSpecialFields.value,
        showSpecialFieldsForm: showSpecialFieldsForm.value,
        showRequireApprovalCode: showRequireApprovalCode.value,
      });

      emit("next");
    };

    onMounted(async () => {
      if (props.configuration) {
        providers.value = props.configuration.providers;
        specialFields.value = props.configuration.specialFields;
        serviceSpecialFields.value = props.configuration.serviceSpecialFields;
        miscs.value = props.configuration.miscs;
        showRequireApprovalCode.value =
          props.configuration.showRequireApprovalCode;

        return;
      }

      loading.value = true;
      if (props.service) {
        showRequireApprovalCode.value = props.service.value.requireApprovalCode;
      }

      await _getSpecialFields(props.location.locationConfigurationId).then(
        ({ data }) => {
          specialFields.value = data;
        }
      );

      await _getServiceSpecialFields(props.service.value.id).then(
        ({ data }) => {
          serviceSpecialFields.value = data;
        }
      );

      await _getCarriers().then((response) => {
        providers.value = response.data.map((x) => ({
          id: x.value,
          name: x.text,
        }));
      });

      if (specialFields.value.specialField3Enabled) {
        await _getMiscData(props.location.locationConfigurationId).then(
          ({ data }) => {
            miscs.value = data;
          }
        );
      }

      loading.value = false;
    });

    const style = computed(() => {
      return {
        "--title-size": props.designConfiguration?.contentTitleFontSize
          ? `${props.designConfiguration?.contentTitleFontSize}px`
          : "28px",
        "--title-color":
          props.designConfiguration?.contentTitleFontColor || "#171717",
        "--control-icon-color":
          props.designConfiguration?.formControlIconColor || "#ffa21a",
        "--control-text-color":
          props.designConfiguration?.formControlLabelColor || "#272727",
        "--control-font-color":
          props.designConfiguration?.fieldsetHeaderFontColor || "#ffffff",
        "--control-background-color":
          props.designConfiguration?.fieldsetHeaderBackgroundColor || "#ffa21a",
        "--calendar-background-color":
          props.designConfiguration?.calendarItemBackgroundColor || "white",
        "--calendar-selected-background-color":
          props.designConfiguration?.calendarSelectedDateBackgroundColor ||
          "#ffa21a",
        "--calendar-text-color":
          props.designConfiguration?.calendarItemFontColor || "#343434",
        "--calendar-hover-color":
          props.designConfiguration?.calendarItemBorderColor || "#faebd5",
        "--return-button-text-color":
          props.designConfiguration?.buttonFontColor || "#343434",
        "--return-button-background-color":
          props.designConfiguration?.buttonBackgroundColor || "transparent",
        "--return-button-border-color":
          props.designConfiguration?.buttonBorderColor || "#343434",
        "--return-button-text-color-active":
          props.designConfiguration?.buttonModalFontColor || "white",
        "--return-button-background-color-active":
          props.designConfiguration?.buttonModalBackgroundColor || "#ffa21a",
        "--return-button-border-color-active":
          props.designConfiguration?.buttonModalBorderColor || "#ffa21a",
      };
    });

    return {
      loading,
      model,
      onSubmit,
      showTerms,
      terms,
      providers,
      specialFields,
      serviceSpecialFields,
      miscs,
      showSpecialFieldsForm,
      style,
      showRequireApprovalCode,
      validationMessage,
      showTelephone,
      firstNameDisabled,
      lastNameDisabled,
      secondLastNameDisabled,
      emailDisabled,
      phoneDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: normal;
  .marked {
    font-weight: bold;
  }
}

.terms {
  background-color: var(--control-background-color);
  padding: 15px 30px;
  border-radius: 4px;
}
</style>
