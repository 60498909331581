<template>
  <div class="form-group" :class="customClass" :style="style">
    <label
      v-if="label"
      :for="`input-${label}`"
      :style="{
        color: `${fontColor} !important`,
      }"
    >
      {{ label }}:
    </label>

    <div class="input-group">
      <div v-if="prependIcon" class="input-group-prepend">
        <span class="input-group-text">
          <i class="bi" :class="prependIcon" :style="{ color: colorIcon }" />
        </span>
      </div>
      <input
        v-bind="$attrs"
        :id="`input-${label}`"
        ref="inputRef"
        v-model="inputValue"
        v-maska="maskBinded"
        autocomplete="none"
        class="form-control"
        :class="{
          prepend: prependIcon,
          clearable: clearable && inputValue,
          'is-invalid': !!errorMessage,
        }"
        :data-maska="mask"
        :disabled="disabled"
        :style="{
          fontSize: `${fontSize} !important`,
        }"
        :type="type"
        @blur="handleBlur"
        @input="handleChange"
        @paste="isDisabledPaste"
      />
      <div
        v-if="clearable && inputValue"
        class="input-group-append cursor-pointer"
        @click="handleChange('')"
      >
        <span class="input-group-text">
          <i class="bi bi-x-lg" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, toRef, reactive, ref } from "vue";
import { useField } from "vee-validate";
import { vMaska, tokens } from "maska";

tokens.A = {
  pattern: /[A-Za-z]/,
  transform: (v) => v.toUpperCase(),
};

tokens.a = {
  pattern: /[A-Za-z]/,
  transform: (v) => v.toLowerCase(),
};

export default {
  name: "BaseInput",
  directives: { maska: vMaska },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    weight: {
      type: Number,
      default: 400,
    },
    size: {
      type: String,
      default: "12pt",
    },
    color: {
      type: String,
      default: "#272727",
    },
    step: {
      type: Number,
      default: 0.01,
    },
    mask: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    colorIcon: {
      type: String,
      default: "#ffa21a",
    },
    disablePaste: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isDisabledPaste(event) {
      if (this.disablePaste) {
        event.preventDefault();
      }
    },
  },
  setup(props) {
    const fontWeight = computed(() => props.weight || 400);
    const fontSize = computed(() => props.size || "14pt");
    const fontColor = computed(() => props.color || "#272727");
    let inputRef = ref(null);

    const maskBinded = reactive({});

    const name = toRef(props, "name");

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.modelValue,
    });

    const style = computed(() => {
      return {
        "--input-font-color": fontColor.value,
      };
    });

    return {
      fontWeight,
      fontSize,
      fontColor,
      inputValue,
      errorMessage,
      meta,
      handleBlur,
      handleChange,
      maskBinded,
      inputRef,
      style,
    };
  },
  watch: {
    maskBinded: {
      deep: true,
      handler(value) {
        if (!this.mask) return;
        this.$emit("unmasked", value.unmasked);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group {
  .invalid-feedback {
    position: absolute;
    max-width: 400px;
  }

  .input-group {
    .input-group-prepend {
      border-right-width: 0;
      .input-group-text {
        background-color: white;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        i {
          color: #ffa21a;
        }
      }
    }
    .input-group-append {
      border-left-width: 0;
      .input-group-text {
        background-color: white;
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .form-control {
      &:focus {
        box-shadow: none;
        border-color: #ced4da;
      }
      &.prepend {
        border-left: 0;
      }
      &.clearable {
        border-right: 0;
      }
    }
  }

  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  &.size-lg {
    .input-group-prepend {
      .input-group-text {
        i::before {
          font-size: 20px;
          font-weight: bold !important;
        }
      }
    }
    .form-control {
      height: 60px;
      font-size: 18px;
      font-weight: 600;
      &::placeholder {
        font-size: 18px;
        font-weight: 600;
        color: var(--input-font-color);
      }
    }
    .input-group-text {
      height: 60px;
    }
  }
}
</style>
