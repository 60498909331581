import { USER_SESSION } from "@/constants/SessionStorage";
export const authHandler = (config) => {
  const userSession = sessionStorage.getItem(USER_SESSION);
  const user = JSON.parse(userSession);
  if (user && user.access_token) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
  } else {
    config.headers.Authorization = undefined;
  }
};
