import { watch } from "vue";

export function useScrollable(componentRef) {
  const init = () => {
    let isDown = false;
    let startX;
    let scrollLeft;

    componentRef.value.addEventListener("mousedown", (e) => {
      isDown = true;
      componentRef.value.classList.add("active");
      startX = e.pageX - componentRef.value.offsetLeft;
      scrollLeft = componentRef.value.scrollLeft;
    });
    componentRef.value.addEventListener("mouseleave", () => {
      isDown = false;
      componentRef.value.classList.remove("active");
    });
    componentRef.value.addEventListener("mouseup", () => {
      isDown = false;
      componentRef.value.classList.remove("active");
    });
    componentRef.value.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - componentRef.value.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      componentRef.value.scrollLeft = scrollLeft - walk;
    });
  };

  watch(
    () => componentRef.value,
    (value) => value && init()
  );
}
