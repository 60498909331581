import { apiTurnos } from "@/core/api/";

export const getCompanies = (typeId = null) =>
  apiTurnos.get("api/public/appointment/companies/list", {
    params: {
      typeId,
    },
  });

export const getCompany = (companyId = null) =>
  apiTurnos.get(`api/public/appointment/company/${companyId}`);

export const getTypes = () =>
  apiTurnos.get("api/public/appointment/office/types");
