<template>
  <!-- Footer-->
  <footer class="footer-content text-center py-5" :style="style">
    <div class="container px-5">
      <div
        class="d-flex flex-sm-row flex-column justify-content-between align-items-center"
      >
        <div class="d-flex">
          <img alt="..." src="../assets/img/logo_w.svg" style="height: 3rem" />
        </div>
        <div
          class="d-flex small justify-content-between align-items-center footer-text"
        >
          <div class="mb-2">
            &copy; {{ new Date().getFullYear() }} Copyright by Turnos Media, LLC
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { defineComponent, computed, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { DESING_CONFIGURATION } from "@/constants/SessionStorage";

export default defineComponent({
  name: "FooterComponent",
  setup() {
    const designConfiguration = ref(null);
    const route = useRoute();

    watch(
      () => route.name,
      (value) => {
        if (
          !["createAppointment", "CancelAppointment", undefined].includes(value)
        ) {
          sessionStorage.removeItem(DESING_CONFIGURATION);
        }
        designConfiguration.value = sessionStorage.getItem(DESING_CONFIGURATION)
          ? JSON.parse(sessionStorage.getItem(DESING_CONFIGURATION))
          : {};
      }
    );

    const style = computed(() => {
      return {
        "--footer-background-color":
          designConfiguration.value &&
          designConfiguration.value.footerBackgroundTopColor
            ? designConfiguration.value.footerBackgroundTopColor
            : "#ffa21a",
        "--footer-text-color":
          designConfiguration.value &&
          designConfiguration.value.footerFontTopColor
            ? designConfiguration.value.footerFontTopColor
            : "white",
      };
    });
    return {
      style,
    };
  },
});
</script>
<style scoped>
.footer-content {
  background: var(--footer-background-color);
}

.footer-text {
  color: var(--footer-text-color);
}
</style>
