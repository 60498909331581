import axios from "axios";

// eslint-disable-next-line no-undef
const _env = process.env;

export function useIPAddress() {
  const getIPAddress = async () => {
    let clientIP;

    await axios
      .get(_env.VUE_APP_URL_IP)
      .then((response) => {
        clientIP = response.data.ip;
      })
      .catch(() => {
        clientIP = null; // Error getting IP address
      });

    return clientIP;
  };

  return {
    getIPAddress,
  };
}
