<script setup>
import TopNavbar from "./components/TopNavbar.vue";
import FooterComponent from "./components/FooterComponent.vue";
import eventBus from "@/bus/eventBus";
</script>
<template>
  <top-navbar v-if="loadedStyles" />

  <RouterView />

  <footer-component v-if="loadedStyles" />
</template>
<script>
export default {
  components: {
    TopNavbar,
    FooterComponent,
  },
  data() {
    return {
      loadedStyles: false,
    };
  },
  created() {
    // Listen for the "loadedstyles" event emitted from the router
    eventBus.on("loadedstyles", (status) => {
      this.loadedStyles = status;
    });
  },
};
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
