<template>
  <div
    class="modal fade"
    :class="{ show: modelValue }"
    style="background: rgba(0, 0, 0, 0.7)"
    :style="{ display: modelValue ? 'block' : 'none' }"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            TERMINOS Y CONDICIONES DE ACCESO Y USO PARA LA APLICACION
            WWW.TURNOSPR.COM Versión 1.0
          </h5>
        </div>
        <div class="modal-body">
          <div class="term-header">Contenido</div>
          <div class="term-content">
            <div class="term-content-block">
              Este documento establece los términos y condiciones generales (los
              “Términos y Condiciones”) aplicables al acceso y uso de los
              servicios ofrecidos para la aplicación www.turnospr.com (la
              “Aplicación”). Por el solo hecho de ingresar a la aplicación, los
              Términos y Condiciones aquí dispuestos quedaran. En consecuencia,
              si Usted no esta de acuerdo con los presentes Términos y
              Condiciones, los cuales poseen carácter obligatorio y vinculante,
              deberá abstenerse de acceder a turnospr.com y utilizar sus
              servicios.
            </div>
            <div class="term-content-block">
              Todo texto, información, datos, fotografías, gráficos, código
              HTML, software informático, código fuente y código objeto,
              muestras de audio y video, marcas y logotipos, y similares (en
              adelante el “Contenido”) que aparezcan en este sitio web,
              servicios de dispositivos móviles o aplicaciones de dispositivos
              móviles (en conjunto “Aplicación”) pertenecen a Turnos Media LLC y
              www.turnospr.com, con la dirección PBM 233, 35 Calle Juan Borbón,
              Guaynabo, PR 00969, con correo electrónico info@turnosmedia.com y
              número telefónico 787-399-0998.
            </div>
            <div class="term-content-block">
              Los Usuarios solo pueden utilizar el contenido en un dispositivo
              móvil (por ejemplo, Apple iPhone o iPad, dispositivo Android o
              dispositivo móvil con Microsoft Windows) que posean o controlen, y
              solamente por motivos personales, internos y sin fines
              comerciales. Ningún otro uso del contenido, incluyendo, entre
              otras, cualquier clase de reedición del mismo, esta permitido sin
              previa autorización por escrito otorgada por Turnos Media LLC o
              www.turnospr.com. Todo Usuario que haya violado comprobadamente la
              propiedad intelectual de un tercero mediante la retransmisión o
              publicación de material vinculado con esta Aplicación que infrinja
              los derechos de autor u otros derechos legales de dicho tercero
              será excluido de esta Aplicación.
            </div>
            <div class="term-content-block">
              Todas las marcas registradas utilizadas en esta Aplicación son
              propiedad de Turnos Media LLC o, en unos pocos casos, utilizadas
              con la autorización de sus respectivos titulares. Ningún tercero
              puede utilizar ni reproducir ninguna marca registrada que incluya,
              entre otros, logotipos y dominios de Internet que utilicen las
              marcas registradas Turnos Media LLC y www.turnospr.com (ya sea que
              se usen o no con letras mayúsculas o espacios) sin el previo
              consentimiento por escrito de la Turnos Media LLC y
              www.turnospr.com o del propietario de la marca registrada.
            </div>
            <div class="term-content-block">
              Aparte del derecho de uso no exclusivo, no sublicenciable,
              intransferible, personal y limitado de los Usuarios tal como se
              especifica en la presente, no se confiere ningún derecho a dicho
              contenido ni a porciones del mismo, sin importar la forma en que
              aparezca, mediante su inclusión en esta Aplicación o mediante el
              acceso al mismo por parte del Usuario. La Compañía será
              responsable de todo mantenimiento o soporte técnico de la
              Aplicación; ningún Tercero (como se define en el presente
              documento) será responsable de brindar servicios de mantenimiento
              o soporte técnico para la Aplicación.
            </div>
          </div>
          <div class="term-header">Uso</div>
          <div class="term-content">
            <div class="term-content-block">
              Al usar, acceder, descargar, instalar, obtener o brindar
              información desde y hacia esta Aplicación, se considerara que los
              Usuarios han leído y aceptado estos Términos y Condiciones Los
              Usuarios deben suspender el uso de esta Aplicación inmediatamente
              si no están de acuerdo o no aceptan todos estos Términos y
              Condiciones. La Compañía se reserva el derecho de eliminar o
              prohibir a cualquier Usuario la utilización de esta Aplicación a
              su sola discreción.
            </div>
          </div>
          <div class="term-header">Información</div>
          <div class="term-content">
            <div class="term-content-block">
              Al Usted enviar o proveer información a través de nuestra
              aplicación nosotros podemos almacenar sus siguientes datos:
              nombre, apellido, número de su aparato móvil o número de teléfono,
              correo electrónico, y cualquier información que usted nos haya
              brindado. También, podríamos almacenar su dirección IP,
              información sobre su navegador de Internet, y recopilar
              información sobre su actividad en nuestra página.
            </div>
          </div>
          <div class="term-header">
            Su información se comparte con empresas y terceros comerciantes:
          </div>
          <div class="term-content">
            <div class="term-content-block">
              Su información se comparte con empresas y terceros comerciantes:
              remotamente cuando visite alguna oficina, también se ofrecen
              servicios o productos para fines publicitarios, de otras empresas
              (los “PROVEEDORES“ e individualmente el “PROVEEDOR“). Los
              PROVEEDORES son los únicos y exclusivos responsables de los
              servicios y productos indicados en los mismos, y además son
              quienes exclusivamente realizan esas prestaciones con arreglo a
              sus respectivas Condiciones Generales de Contratación. Turnos
              Media LLC podrá usar su correo electrónico y/o número de teléfono
              celular para enviarle promociones publicitarias y a su a vez
              compartirlos con terceros comerciantes. Las promociones
              publicitarias podrían ser a través de correos electrónicos,
              mensajes de textos o llamadas telefónicas. Al usuario registrar su
              información de correo electrónico y número de teléfono esta
              accediendo al recibió de correos electrónicos, al recibo del
              mensaje de texto y llamadas de voz comerciales y/o promocionales.
              YO CONSIENTO EN RECIBIR MENSAJES DE CORREO ELECTRONICOS, MENSAJES
              DE TEXTO, Y/O LLAMADAS DE VOZ SOBRE PUBLICIDAD DE PARTE DE TURNOS
              MEDIA LLC Y/O UN TERCERO AUTORIZADO POR TURNOS MEDIA LLC AL CORREO
              ELECTRONICO Y/O NUMERO DE TELEFONO VOLUNTARIAMENTE PROVISTO POR
              MI. ENTIENDO QUE ESTE CONSENTIMIENTO NO ES UNA CONDICION DE
              COMPRA.
            </div>
            <div class="term-content-block">
              WWW.TURNOSPR.COM solo otorga un espacio para que los PROVEEDORES
              publiciten, promocionen y comercialicen sus servicios y ofertas
              por ellos prestados o productos por ellos comercializados.
              WWW.TURNOSPR.COM no es el propietario ni tiene la posesión de los
              productos ni presta los servicios promocionados en la aplicación,
              ni ninguna garantía presta en relación a los mismos.
            </div>
            <div class="term-content-block">
              Usted reconoce y acepta que ni Turnos Media LLC, ni
              WWW.TURNOSPR.COM no asumen obligación ni responsabilidad alguna en
              relación a los productos y servicios objeto y que toda obligación
              relativa a los mismos es de exclusiva responsabilidad de los
              respectivos PROVEEDORES.
            </div>
            <div class="term-content-block">
              El PROVEEDOR respectivo realiza la prestación del servicio o la
              venta del producto en nombre propio y por cuenta propia frente al
              Usuario, por lo que Turnos Media LLC y WWW.TURNOSPR.COM no
              responden, frente al Usuario ni frente a ningún tercero, por el
              incumplimiento de obligaciones del PROVEEDOR.
            </div>
            <div class="term-content-block">
              En caso que el PROVEEDOR hubiera fijado sus propios términos y
              condiciones para los productos y servicios ofrecidos en este
              Sitio, ellos aparecerán en este Sitio señalados con un link o
              mencionados como parte de la promoción de sus ofertas y
              promociones. Dichas condiciones también serán vinculantes y
              obligatorias para el Usuario. Turnos Media LLC, ni
              WWW.TURNOSPR.COM no asumen ninguna responsabilidad que se derive
              de cualquier daño o perjuicio, ya sea directo o indirecto,
              relacionado con el acceso o utilización del Sitio. Tampoco serás
              responsables Turnos Media LLC, ni WWW.TURNOSPR.COM de los daños
              provocados por virus que infecten el sistema o computadora del
              Usuario, ni por el mal uso que se realice a las aplicaciones o de
              los contenidos del Sitio, recayéndose exclusivamente la
              responsabilidad en aquel Usuario que haya realizado el mal uso.
            </div>
            <div class="term-content-block">
              Ni la empresa Tunos Media LLC, ni WWW.TURNOSPR.COM no se
              responsabilizan por la información contenida en el Sitio de
              terceros a los que se pueda acceder a través de enlaces (links), o
              por la información contenida en el Sitio de terceros vinculada de
              algún modo a este Sitio, ni por el intercambio de información
              entre Usuarios a través del Sitio.
            </div>
            <div class="term-content-block">
              Turnos Media LLC y WWW.TURNOSPR.COM se reservan el derecho a
              denegar o retirar el acceso al Sitio, en cualquier momento y sin
              necesidad de preaviso, por iniciativa propia o a instancia de un
              tercero, a aquellos Usuarios que violen la ley o que incumplan las
              presentes Términos y Condiciones Generales o bien vulneren los
              derechos existentes sobre los contenidos, productos y/o servicios
              del Sitio. Las descripciones de los servicios y/o productos
              promocionado en el Sitio, se realiza en base a la información
              brindada por los PROVEEDORES. Las fotografías, imágenes y/o videos
              relativos a los servicios y/o productos son ilustrativos.
            </div>
          </div>
          <div class="term-header">
            Derecho de Revocación y de Exclusión Voluntaria.
          </div>
          <div class="term-content">
            <div class="term-content-block">
              Conforme a las normas de la Federal Communications Commission
              (FCC), los mensajes comerciales por correo electrónico o texto,
              pueden enviarse si ha dado su autorización expresa. Usted, tendrá
              la facultad de revocar su autorización para el uso de su
              información y no recibir mensajes de correo electrónico y/o de
              texto por parte de www.turnospr.com, dentro del plazo de diez (10)
              días, sin necesidad de indicar las causas, comunicándolo por
              escrito (ej. carta o correo electrónico) al siguiente correo
              electrónico info@turnosmedia.com o a través del enlace en la parte
              de abajo del mensaje de correo electrónico.
            </div>
          </div>
          <div class="term-header">
            Exoneración y Relevo de Responsabilidad:
          </div>
          <div class="term-content">
            <div class="term-content-block">
              Los Usuarios liberaran de toda responsabilidad y exoneraran a
              Turnos Media LLC y www.turnospr.com de todo reclamo, demanda,
              responsabilidad civil, causa legal, querella o danos y perjuicios
              (incluidos los honorarios y los gastos razonables de abogados) que
              surjan como consecuencia del uso que dichos Usuarios hagan de la
              Aplicación (incluidos nuestros productos, servicios y Contenido),
              incluyendo, entre otros, la información, contenido o entrega
              incorrectos de la Aplicación, o de los productos y servicios de
              www.turnospr.com o de terceros. La Compañía se reserva el derecho,
              por cuenta propia, de asumir la defensa y el control exclusivos de
              cualquier asunto sujeto a exoneración por parte de los Usuarios,
              pero el hacerlo no exime a los Usuarios de sus obligaciones de
              exoneración.
            </div>
          </div>
          <div class="term-header">Derecho Propietario:</div>
          <div class="term-content">
            <div class="term-content-block">
              Las marcas, nombres comerciales, imágenes, fotografías, videos,
              dibujos, diseños, logos, textos etc. que aparecen publicados en la
              aplicación y en el sitio web de www.turnospr.com, como así también
              los programas, “software”, base de datos, archivos, etc. son de
              propiedad exclusiva de Turnos Media LLC y WWW.TURNOSPR.COM o de
              terceros que ha autorizado a Turnos Media LLC y WWW.TURNOSPR.COM a
              utilizar y/o publicar los mismos. Queda expresamente prohibido
              cualquier uso, explotación, distribución, publicación, transmisión
              y/o copia sin la previa y expresa autorización por escrito de
              Turnos Media LLC y WWW.TURNOSPR.COM y/o de sus respectivos
              propietarios. El diseño y contenido de esta aplicación pertenece
              en forma exclusiva a Turnos Media LLC y a WWW.TURNOSPR.COM y se
              encuentra protegido por la Ley de Propiedad Intelectual, estando
              expresamente prohibido a los Usuarios y terceros modificar,
              copiar, distribuir, transmitir, publicar, editar, reproducir,
              explotar de cualquier modo, y a través de cualquier medio,
              cualquiera sea el fin. Quien violare lo aquí dispuesto será
              responsable de las acciones civiles correspondientes.
            </div>
          </div>
          <div class="term-header">Terceros</div>
          <div class="term-content">
            <div class="term-content-block">
              Los prestadores de servicio de telefonía inalámbrica de los
              Usuarios, los fabricantes y vendedores de los aparatos móviles en
              los que el Usuario descargue, instale, utilice o acceda a la
              Aplicación, el creador del sistema operativo para los aparatos
              móviles de los Usuarios y el operador de cualquier tienda de
              aplicaciones o servicios similares mediante los cuales los
              usuarios obtengan la Aplicación, si existieran, (en conjunto, los
              "Terceros") no son parte de estos Términos y Condiciones y no son
              propietarios ni responsables de la Aplicación. Los Terceros no
              brindan ninguna garantía en relación con la Aplicación. No son
              responsables del mantenimiento u otros servicios de soporte
              técnico de la Aplicación y no serán responsables ante ningún otro
              reclamo, perdidas, imputación de responsabilidades, danos y
              perjuicios, costos o gastos vinculados con la Aplicación.
            </div>
            <div class="term-content-block">
              La Aplicación fue creada para la versión mas reciente disponible
              en el mercado de los sistemas operativos de los dispositivos
              móviles de los Usuarios y pueden surgir inconvenientes de
              compatibilidad cuando se utilicen versiones anteriores. La
              cobertura de la red inalámbrica y la velocidad de la red de Wi-Fi
              varían según el proveedor y la ubicación geográfica. Turnos Media
              LLC y WWW.TURNOSPR.COM no se responsabiliza por las limitaciones
              y/o fallas en el funcionamiento de ningún servicio inalámbrico o
              W-FI que se use para acceder a esta Aplicación ni por la seguridad
              de los servicios inalámbricos o W-Fi. Asimismo, no se
              responsabiliza de los cargos o tarifas por uso de redes de datos,
              que son exclusiva responsabilidad del Usuario.
            </div>
          </div>
          <div class="term-header">Actividades ilegales:</div>
          <div class="term-content">
            <div class="term-content-block">
              No está permitido ningún acto, ya sea a través de un software u
              otro medio, tendiente a interferir tanto en las actividades y
              operatoria de Turnos Media LLC y WWW.TURNOSPR.COM como en las
              ofertas, descripciones, cuentas o bases de datos de la misma.
            </div>
          </div>
          <div class="term-header">Advertencia al Usuario:</div>
          <div class="term-content">
            <div class="term-content-block">
              Turnos Media LLC y WWW.TURNOSPR.COM se reserva el derecho de
              rechazar cualquier solicitud de registro o de cancelar un registro
              previamente aceptado, sin tener que comunicar o exponer las
              razones de su decisión y sin que ello genere algún derecho al
              Usuario de indemnización o resarcimiento.
            </div>
            <div class="term-content-block">
              Si el Usuario violare cualquiera de los Términos y Condiciones se
              hará responsable de las acciones penales y civiles que pudieran
              corresponder.
            </div>
            <div class="term-content-block">
              En caso que cualquiera de las disposiciones de estos Términos y
              Condiciones Generales de Uso del Sitio fueran consideradas nulas o
              inválidas ya sea en forma total o parcial, ello no afectara a la
              validez del resto de las previsiones incluidas en los presentes
              Términos y Condiciones.
            </div>
          </div>
          <div class="term-header">
            Envie un mensaje si tiene alguna pregunta sobre estos términos y
            condiciones:
          </div>
          <div class="term-content">
            <div class="term-content-block">
              Estas condiciones de uso podrán ser consultadas, impresas o
              grabadas localmente en cualquier momento bajo:
              http://www.turnospr.com/condiciones
            </div>
          </div>
          <div class="term-header">Jurisdicción:</div>
          <div class="term-content">
            <div class="term-content-block">
              Los presentes Términos y Condiciones se rigen por las leyes del
              Estado Libre Asociado y cualquier reglamentación federal que
              pudiere aplicar. Los Usuarios acuerdan someterse a la jurisdicción
              del Tribunal Superior de Puerto Rico o el Tribunal de Distrito
              Federal, renunciando en forma expresa a cualquier otra
              jurisdicción que pudiera corresponderles.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            @click="$emit('update:modelValue', false)"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.term-header {
  color: #ff8105;
  border-bottom: 1px solid #e0e0e0;
}
.term-content {
  margin: 6px 0px;
  .term-content-block {
    font-size: 14px;
    color: #1c1d21;
    margin-bottom: 18px;
  }
}
</style>
